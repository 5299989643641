/**
 * Utility function to get the closest element that is truncated.
 * This is required since some components already have pre-existing refs set up or
 * do not allow refs to be set on those components.
 * If there is no element that is truncated, return the passed in element.
 * @param el
 * @returns
 */
export const getClosestTextElement = (el: Element): Element | null => {
	if (!el || !el.children || el.children.length === 0) return el;

	const elements = [...el.children];

	for (const e of elements) {
		if (e.clientWidth !== e.scrollWidth) {
			return e;
		}

		for (const child of e.children) {
			elements.push(child);
		}
	}

	return el;
};

/**
 * Utility function to test whether an elements content has been truncated using line clamp.
 *
 * @param el
 * @returns boolean
 */
export const checkIsClamped = (el: Element | null | undefined): boolean => {
	if (el) {
		const currentStyle = getComputedStyle(el);
		const lineClamp = parseInt(currentStyle.getPropertyValue('-webkit-line-clamp'), 10);

		if (lineClamp > 0 && currentStyle.getPropertyValue('overflow') === 'hidden') {
			return el.scrollHeight > el.clientHeight;
		}
		return false;
	}

	return false;
};
