/** @jsx jsx */
import React, { memo } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import type { fallback_nativeIssueTable_FallbackCell$key } from '@atlassian/jira-relay/src/__generated__/fallback_nativeIssueTable_FallbackCell.graphql';
import ErrorCell from '../../error-cell/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';
import { HoverPopover } from '../../hover-popover/index.tsx';

export type Props = {
	fieldRef: fallback_nativeIssueTable_FallbackCell$key;
};

/**
 * Render fallback version of the issue field cell that is generated by the backend.
 * We trust the backend to do all HTML entity encoding and other sanitization.
 */
export const FallbackCell = memo<Props>(({ fieldRef }) => {
	const { renderedFieldHtml, type } = useFragment<fallback_nativeIssueTable_FallbackCell$key>(
		graphql`
			fragment fallback_nativeIssueTable_FallbackCell on JiraFallbackField {
				renderedFieldHtml
				type
			}
		`,
		fieldRef,
	);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	if (renderedFieldHtml == null) {
		return <ErrorCell cellType="fallback" reason="Missing required fields" />;
	}

	if (!renderedFieldHtml.trim().length) {
		return null;
	}

	switch (type) {
		case 'thumbnail':
			return isSingleLineRowHeightEnabled ? (
				<HoverPopover
					content={
						<div
							css={attachmentPopoverStyle}
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
						/>
					}
				>
					<AttachmentFallbackContainer
						data-testid="native-issue-table.common.ui.issue-cells.fallback.attachment-fallback-container"
						dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
						isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
					/>
				</HoverPopover>
			) : (
				<AttachmentFallbackContainer
					data-testid="native-issue-table.common.ui.issue-cells.fallback.attachment-fallback-container"
					dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
					isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
				/>
			);
		case 'progress':
		case 'aggregateprogress':
			return (
				<ProgressFallbackContainer
					data-testid="native-issue-table.common.ui.issue-cells.fallback.progress-fallback-container"
					dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
				/>
			);
		case 'com.atlassian.servicedesk:sd-request-feedback':
			return (
				<SatisfactionFallbackContainer
					data-testid="native-issue-table.common.ui.issue-cells.fallback.satisfaction-fallback-container"
					dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
				/>
			);
		case 'com.atlassian.servicedesk:sd-sla-field':
			return (
				<SlaFallbackContainer
					data-testid="native-issue-table.common.ui.issue-cells.fallback.sla-fallback-container"
					dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
				/>
			);
		default:
			return (
				<FallbackContainer
					data-testid="native-issue-table.common.ui.issue-cells.fallback.div"
					dangerouslySetInnerHTML={{ __html: renderedFieldHtml }}
				/>
			);
	}
});

/**
 * Disabling selector eslint rules because we must provide the fallback style overrides this way due
 * to the markup and base styles coming from the monolith.
 */
/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FallbackContainer = styled.div<{ isSingleLineRowHeightEnabled?: boolean }>({
	maxWidth: '100%',
	paddingBlock: token('space.050'),
	paddingInline: token('space.100'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	img: {
		height: 'auto',
		maxWidth: '100%',
	},
});

const attachmentPopoverStyle = css({
	img: { objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttachmentFallbackContainer = styled(FallbackContainer)<{
	isSingleLineRowHeightEnabled: boolean;
}>(
	{
		img: {
			// Override height & width provided by monolith, and prevent it from being stretched
			height: 'auto',
			maxHeight: '200px', // Limit height to the same as GIN to prevent cells from getting too tall
			width: 'auto',
			maxWidth: '100%',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(isSingleLineRowHeightEnabled) =>
		isSingleLineRowHeightEnabled && {
			paddingBlock: token('space.025'),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			img: {
				maxHeight: '30px',
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressFallbackContainer = styled(FallbackContainer)({
	lineHeight: 0,
	table: {
		borderCollapse: 'separate',
		tbody: {
			td: {
				// Need to keep this 1px padding because it targets markup from the legacy renderer

				padding: '1px',
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SatisfactionFallbackContainer = styled(FallbackContainer)({
	'.feedback-star': {
		color: token('color.icon.accent.yellow'),
	},
	'.feedback-unstar': {
		color: token('color.text.subtlest'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SlaFallbackContainer = styled(FallbackContainer)({
	'.sla-tag': {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.100'),
		textAlign: 'right',
	},
	'.sla-tag div': {
		flexBasis: '72px',
	},
	'.sla-tag-successful .sla-status-icon': {
		color: token('color.icon.accent.green'),
	},
	'.sla-tag-unsuccessful .sla-status-icon': {
		color: token('color.icon.accent.red'),
	},
	'.sla-tag-pause .sla-status-icon': {
		color: token('color.icon'),
	},
	'.sla-tag-ongoing .sla-status-icon': {
		color: token('color.icon.accent.teal'),
	},
	'.sla-tag-breached .sla-status-icon': {
		color: token('color.icon.accent.red'),
	},
	'.sla-tag-urgent .sla-status-icon': {
		color: token('color.icon.accent.orange'),
	},
});
