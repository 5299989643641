import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { FileIdentifier, MediaClientConfig } from '@atlaskit/media-client';
import type { ClientBasedAuth } from '@atlaskit/media-core';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import ListCellWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import type { cmdbObjects_nativeIssueTable_CmdbObjectsCell$key } from '@atlassian/jira-relay/src/__generated__/cmdbObjects_nativeIssueTable_CmdbObjectsCell.graphql';
import { createObjectDetailUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { isNonNullish } from '../../../utils/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { useIsSingleLineRowHeightEnabled } from '../../../../controllers/features/selectors.tsx';
import { createObjectDetailUrl as createObjectDetailUrlOld } from './constants.tsx';
import messages from './messages.tsx';

type Props = {
	fieldRef: cmdbObjects_nativeIssueTable_CmdbObjectsCell$key;
};

const CELL_TYPE = 'cmdbObjects';

export const CmdbObjectsCell = memo<Props>(({ fieldRef }: Props) => {
	const { formatMessage } = useIntl();
	const data = useFragment<cmdbObjects_nativeIssueTable_CmdbObjectsCell$key>(
		graphql`
			fragment cmdbObjects_nativeIssueTable_CmdbObjectsCell on JiraCMDBField {
				selectedCmdbObjectsConnection {
					edges {
						node {
							objectId
							label
							avatar {
								mediaClientConfig {
									clientId
									fileId
									mediaBaseUrl
									mediaJwtToken
								}
								url48
							}
						}
					}
					errors {
						message
					}
				}
			}
		`,
		fieldRef,
	);

	const errors = data?.selectedCmdbObjectsConnection?.errors?.map((error) => error?.message);

	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	if (errors?.length) {
		// At time of writing, these errors are not wired up on the issue backend. However we will maintain this
		// logging so that it is present if/when it is wired up so we can get error information immediately.
		return (
			<ErrorCell
				cellType={CELL_TYPE}
				reason={`Errors present: ${errors.length} - ${errors.join(', ')}; Edges present: ${data?.selectedCmdbObjectsConnection?.edges?.length}`}
			/>
		);
	}

	const objects = data?.selectedCmdbObjectsConnection?.edges;

	const filteredObjectsWithMedia =
		objects
			?.map((edge) => {
				if (!edge?.node?.label) {
					return null;
				}

				let mediaImage;
				const iconUrl = edge.node.avatar?.url48 ?? undefined;

				if (
					edge.node.avatar?.mediaClientConfig?.clientId &&
					edge.node.avatar?.mediaClientConfig?.fileId &&
					edge.node.avatar?.mediaClientConfig?.mediaBaseUrl &&
					edge.node.avatar?.mediaClientConfig?.mediaJwtToken
				) {
					const identifier: FileIdentifier = {
						id: edge.node.avatar.mediaClientConfig.fileId,
						mediaItemType: 'file',
					};
					const clientBasedAuth: ClientBasedAuth = {
						clientId: edge.node.avatar.mediaClientConfig.clientId,
						token: edge.node.avatar.mediaClientConfig.mediaJwtToken,
						// eslint-disable-next-line jira/deprecations/no-base-url
						baseUrl: edge.node.avatar.mediaClientConfig.mediaBaseUrl,
					};
					const mediaClientConfig: MediaClientConfig = {
						authProvider: () => Promise.resolve(clientBasedAuth),
					};
					mediaImage = {
						identifier,
						mediaClientConfig,
					};
				}

				return {
					key: edge.node.objectId || 'missingId',
					name: edge.node.label,
					href: fg('assets_as_an_app_v2')
						? createObjectDetailUrl(edge.node.objectId || '')
						: createObjectDetailUrlOld(edge.node.objectId || ''),
					icon: {
						url: !mediaImage ? iconUrl : undefined,
						mediaImage,
					},
				};
			})
			.filter(isNonNullish) ?? [];

	if (!objects || objects.length !== filteredObjectsWithMedia.length) {
		return <ErrorCell cellType={CELL_TYPE} reason="Missing required fields" />;
	}

	return (
		<ListCellWithPopup
			items={filteredObjectsWithMedia}
			ItemComponent={TagItem}
			PopupContentWrapper={TagItemPopupContentWrapper}
			showMoreTooltip={formatMessage(messages.triggerButton)}
			isHoverPopoverEnabled={isSingleLineRowHeightEnabled}
		/>
	);
});
