import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type { groupTableBody_nativeIssueTable_groupResults$key } from '@atlassian/jira-relay/src/__generated__/groupTableBody_nativeIssueTable_groupResults.graphql.ts';
import type { groupTableBody_nativeIssueTable_project$key } from '@atlassian/jira-relay/src/__generated__/groupTableBody_nativeIssueTable_project.graphql.ts';
import { getFlatListKey } from '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-flat-list-key/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useHierarchyActions } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { ChildRows as ChildRowsNew } from '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows/index.tsx';
import { ChildRowsLoader } from '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-loader/index.tsx';
import { Rows } from '../../rows/index.tsx';
import type { Column, TableInjectedCellProps, SortOrder } from '../../../common/types.tsx';
import {
	useIsVirtualizationEnabled,
	useIsReparentingEnabled,
	useSortDirection,
	useSortField,
	useJql,
	useIsFixedTableLayoutEnabled,
	useIsIssueRankDescending,
	useIsHideDoneItemsEnabled,
} from '../../../controllers/features/selectors.tsx';
import { FirstGroupRow, GroupRow } from '../../row/group-row/index.tsx';
import { ChildRows as ChildRowsOld } from '../../rows/child-rows/index.tsx';
import { RowContextProvider, type RenderChildRows } from '../../rows/row-context/index.tsx';
import { VirtualizedRows } from '../../rows/virtualized-rows-hierarchy/index.tsx';
import {
	ANALYTICS_SOURCE,
	GROUPED_ITEMS_EXPERIENCE,
	GROUPED_LOAD_MORE_EXPERIENCE,
	MAX_COLUMNS,
	ACTION_COLUMNS,
	CHILD_QUERY_PAGE_SIZE,
} from '../../../common/constants.tsx';

import { TableCell } from '../../../common/ui/table-cell/index.tsx';
import { useScrollStateSelector } from '../../../controllers/scroll-state/index.tsx';
import { CreateIssueController } from '../../../services/issue-create-mutation/index.tsx';

export type Props = {
	groupResults: groupTableBody_nativeIssueTable_groupResults$key;
	columns: Column[];
	loading: boolean;
	tableInjectedProps: TableInjectedCellProps;
	project: groupTableBody_nativeIssueTable_project$key | null;
};

export const GroupTableBody = ({
	groupResults,
	project,
	columns,
	loading,
	...tableBodyProps
}: Props) => {
	const groupsData = useFragment<groupTableBody_nativeIssueTable_groupResults$key>(
		graphql`
			fragment groupTableBody_nativeIssueTable_groupResults on JiraSpreadsheetGroupConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isDensityFull: { type: "Boolean", defaultValue: false }
				isPaginating: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
			) {
				__id
				firstGroup @skip(if: $isPaginating) {
					id
					...groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow
						@arguments(
							isInlineEditingEnabled: $isInlineEditingEnabled
							isDensityFull: $isDensityFull
							projectKeys: $projectKeys
							projectKey: $projectKey
						)
				}
				edges {
					node {
						id
						...groupRow_nativeIssueTable_groupRow
					}
				}
			}
		`,
		groupResults,
	);

	const projectData = useFragment(
		graphql`
			fragment groupTableBody_nativeIssueTable_project on JiraProject {
				key
			}
		`,
		project,
	);

	const groupConnectionId = groupsData.__id;
	const { populateConnection } = useHierarchyActions();
	const populateConnectionArgs = useMemo(
		() => ({
			parentItemId: 'root',
			itemsConnectionId: groupConnectionId,
			parentConnectionId: groupConnectionId,
			list:
				groupsData?.edges
					// groupId for each group is the same as itemId
					?.map((edge) => edge?.node && { itemId: edge.node.id, key: null, groupId: edge.node.id })
					.filter(Boolean) ?? [],
		}),
		[groupConnectionId, groupsData.edges],
	);
	const prevPopulateConnectionArgs = usePrevious(populateConnectionArgs);

	if (!isEqual(populateConnectionArgs, prevPopulateConnectionArgs)) {
		populateConnection(populateConnectionArgs);
	}

	/**
	 * Passing those values down to ChildRowsLoader as props to avoid direct imports
	 */
	let jql: string | undefined;
	let sortField: string | undefined;
	let sortDirection: SortOrder | undefined;
	let isReparentingEnabled = false;
	let isHideDoneItemsEnabled = false;
	let isFixedTableLayoutEnabled = false;
	let width = 0;
	let isIssueRankDescending = false;
	if (fg('jira_list_hierarchy_extraction')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		jql = useJql();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		sortField = useSortField();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		sortDirection = useSortDirection();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isReparentingEnabled = useIsReparentingEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isHideDoneItemsEnabled = useIsHideDoneItemsEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isFixedTableLayoutEnabled = useIsFixedTableLayoutEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		width = useScrollStateSelector((scrollState) => scrollState.width);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueRankDescending = useIsIssueRankDescending();
	}

	const renderChildRows: RenderChildRows = useCallback(
		(props) =>
			fg('jira_list_hierarchy_extraction') ? (
				<ChildRowsNew
					projectKey={projectData?.key}
					isGroupingSupported
					childItemsExperience={GROUPED_ITEMS_EXPERIENCE}
					childItemsLoadMoreExperience={GROUPED_LOAD_MORE_EXPERIENCE}
					analyticsSource={ANALYTICS_SOURCE}
					renderChildRowsLoader={(childRowsLoaderProps) => (
						<ChildRowsLoader
							{...childRowsLoaderProps}
							jql={jql}
							width={width}
							sortField={sortField}
							sortDirection={sortDirection}
							columns={columns}
							amountOfColumns={MAX_COLUMNS}
							actionColumns={ACTION_COLUMNS}
							pageSize={CHILD_QUERY_PAGE_SIZE}
							isReparentingEnabled={isReparentingEnabled}
							isIssueRankDescending={isIssueRankDescending}
							isHideDoneItemsEnabled={isHideDoneItemsEnabled}
							isFixedTableLayoutEnabled={isFixedTableLayoutEnabled}
							renderRows={(rowProps) => <Rows {...rowProps} />}
							renderTableCell={(tableCellProps) => <TableCell {...tableCellProps} />}
							renderCreateIssueController={(args) => <CreateIssueController {...args} />}
						/>
					)}
					{...props}
				/>
			) : (
				<ChildRowsOld projectKey={projectData?.key} isGroupingSupported {...props} />
			),
		[
			isFixedTableLayoutEnabled,
			isHideDoneItemsEnabled,
			isIssueRankDescending,
			isReparentingEnabled,
			jql,
			projectData?.key,
			sortDirection,
			sortField,
			columns,
			width,
		],
	);

	const renderGroups = useCallback(
		(isItemVirtual?: (itemId: string) => boolean) =>
			groupsData?.edges?.map(
				(group, index) =>
					group?.node &&
					// We need to render the first group separately because it requires initialization during the table rendering process.
					// Additionally, it bypasses the ChildRowLoader and directly uses ChildRowsPagination, as the data is already available in data.firstGroup.
					(groupsData?.firstGroup && group.node.id === groupsData.firstGroup.id ? (
						<RowContextProvider
							key={groupsData.firstGroup.id}
							groupId={groupsData.firstGroup.id}
							{...tableBodyProps}
							columns={columns}
							renderChildRows={(props) =>
								renderChildRows({ ...props, groupId: groupsData.firstGroup?.id })
							}
						>
							<FirstGroupRow
								firstGroupRow={groupsData.firstGroup}
								groupConnectionId={groupConnectionId}
								loading={loading}
								isVirtual={
									isItemVirtual?.(
										getFlatListKey({
											itemId: groupsData.firstGroup.id,
											groupId: groupsData.firstGroup.id,
										}),
									) ?? true
								}
								isGroupAtTop={index === 0}
								projectKey={projectData?.key}
							/>
						</RowContextProvider>
					) : (
						<RowContextProvider
							key={group.node.id}
							groupId={group.node.id}
							{...tableBodyProps}
							columns={columns}
							renderChildRows={(props) => renderChildRows({ ...props, groupId: group.node?.id })}
						>
							<GroupRow
								groupRow={group.node}
								groupConnectionId={groupConnectionId}
								isVirtual={
									isItemVirtual?.(
										getFlatListKey({ itemId: group.node.id, groupId: group.node.id }),
									) ?? true
								}
								isGroupAtTop={index === 0}
								projectKey={projectData?.key}
							/>
						</RowContextProvider>
					)),
			),
		[
			columns,
			groupConnectionId,
			groupsData?.edges,
			groupsData.firstGroup,
			loading,
			projectData?.key,
			renderChildRows,
			tableBodyProps,
		],
	);

	const isVirtualizationEnabled = useIsVirtualizationEnabled();

	return (
		<Box as="tbody" xcss={tableBodyStyles}>
			{isVirtualizationEnabled ? (
				<VirtualizedRows hasSpacers columnsCount={columns.length}>
					{renderGroups}
				</VirtualizedRows>
			) : (
				renderGroups()
			)}
		</Box>
	);
};

const tableBodyStyles = xcss({
	borderBottom: 'none',
	overflowX: 'hidden',
});
