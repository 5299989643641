import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import {
	graphql,
	useFragment,
	usePaginationFragment,
	usePreloadedQuery,
	useQueryLoader,
	type PreloadedQuery,
	type EntryPointProps,
} from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import type { FormPropsType } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import Query, {
	type hierarchy_childRowsPreloadedQuery,
	type JiraIssueSearchInput,
} from '@atlassian/jira-relay/src/__generated__/hierarchy_childRowsPreloadedQuery.graphql';
import type { hierarchy_nativeIssueTable_ChildRowsPagination_query$key } from '@atlassian/jira-relay/src/__generated__/hierarchy_nativeIssueTable_ChildRowsPagination_query.graphql';
import type { hierarchy_nativeIssueTable_ChildRowsPagination_queryData$key } from '@atlassian/jira-relay/src/__generated__/hierarchy_nativeIssueTable_ChildRowsPagination_queryData.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsSelected as useIsSelectedNew } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import {
	useRegisterConnection,
	type ConnectionMetadata,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/connections-list/index.tsx';
import {
	useUpdateHierarchyHasChildrenOnExpand,
	useUpdateGroupIssueCountOnExpand,
} from '@atlassian/jira-issue-table-hierarchy/src/common/utils/cache-utils/index.tsx';
import {
	CHILD_QUERY_PAGE_SIZE,
	CHILD_ITEMS_EXPERIENCE,
	CHILD_ITEMS_LOAD_MORE_EXPERIENCE,
	ANALYTICS_SOURCE,
	GROUPED_ITEMS_EXPERIENCE,
	GROUPED_LOAD_MORE_EXPERIENCE,
} from '../../common/constants.tsx';
import {
	useIsReparentingEnabled,
	useSortDirection,
	useSortField,
	useJql,
	useIsHideDoneItemsEnabled,
} from '../../controllers/features/selectors.tsx';
import { ChildIssueCreateRow } from '../row/child-issue-create-row/index.tsx';
import LoadingIssueRowOld from '../row/loading-row/index.tsx';
import { Rows } from '../rows/index.tsx';
import { useRowContext } from '../rows/row-context/index.tsx';
import {
	getChildQueryVariablesForParentIssue as getChildQueryVariablesForParentIssueOld,
	getChildQueryVariablesForJql as getChildQueryVariablesForJqlOld,
} from './utils.tsx';

const PACKAGE_LOCATION = 'jira-native-issue-table-child-rows-pagination';

type Queries = {
	issueSearch: hierarchy_childRowsPreloadedQuery;
};

type BaseProps = {
	depth: number;
	parentItemId: string;
	itemsConnectionId: string;
	parentIssueAri?: string;
	// `parentItemConnectionId` actually refers to the id of the parent `JiraIssueEdge`, not the connection id
	// it will be renamed in JSC-1333 to match, and `parentIssueRelayConnectionId` will refer to the connection id
	parentItemConnectionId?: string;
	parentIssueRelayConnectionId?: string;
	isFormVisible?: boolean;
	formProps?: FormPropsType;
	connectionMetadata?: ConnectionMetadata;
	projectKey?: string;
	isGroupingSupported?: boolean;
};

interface RowLoaderProps extends BaseProps {
	issueKey: string;
}

interface GroupLoaderProps extends BaseProps {
	groupJql: string;
}

export type Props = BaseProps & (RowLoaderProps | GroupLoaderProps);

export const isGroupLoader = (props: Props): props is GroupLoaderProps =>
	'groupJql' in props && Boolean(props.groupJql);

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-loader/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const ChildRowsLoader = (props: Props) => {
	const [issueSearch, loadQuery, dispose] =
		useQueryLoader<hierarchy_childRowsPreloadedQuery>(Query);

	const cloudId = useCloudId();
	const sortField = useSortField();
	const sortDirection = useSortDirection();
	const jql = useJql();
	const { columns, groupId } = useRowContext();

	const isReparentingEnabled = useIsReparentingEnabled();
	const isHideDoneItemsEnabled = useIsHideDoneItemsEnabled();

	const variables = useMemo(() => {
		if (isGroupLoader(props)) {
			return getChildQueryVariablesForJqlOld({
				cloudId,
				columns,
				jql: props.groupJql,
				isReparentingEnabled: false,
				isHideDoneItemsEnabled,
			});
		}

		return getChildQueryVariablesForParentIssueOld({
			cloudId,
			columns,
			parentIssueKey: props.issueKey,
			sortDirection,
			sortField,
			jql,
			isReparentingEnabled,
			isHideDoneItemsEnabled,
		});
	}, [
		props,
		cloudId,
		columns,
		sortDirection,
		sortField,
		jql,
		isReparentingEnabled,
		isHideDoneItemsEnabled,
	]);

	const prevIssueSearchInput = useRef<JiraIssueSearchInput | null>(variables.issueSearchInput);

	useEffect(() => {
		// This check prevents performing another network request for child items when the issue query changes
		// (which is unused as we re-fetch the issues when this happens). This is due to a race condition where
		// this component has not yet been unmounted when the issue query changes.
		if (
			props.isGroupingSupported &&
			!isEqual(variables.issueSearchInput, prevIssueSearchInput.current)
		) {
			return;
		}

		loadQuery(variables);

		if (props.isGroupingSupported) {
			prevIssueSearchInput.current = variables.issueSearchInput;
		}
		return () => {
			dispose();
		};
	}, [variables, loadQuery, dispose, props.isGroupingSupported]);

	if (!issueSearch) {
		return null;
	}

	const {
		depth,
		parentItemId,
		itemsConnectionId,
		parentIssueAri,
		parentItemConnectionId,
		parentIssueRelayConnectionId,
		connectionMetadata,
		isFormVisible,
		formProps,
		projectKey,
	} = props;

	return (
		<Placeholder
			name="native-issue-table-child-rows-query"
			fallback={<LoadingIssueRowOld itemId={parentItemId} groupId={groupId} />}
		>
			<ChildRowsPreloadedQuery
				depth={depth}
				parentItemId={parentItemId}
				itemsConnectionId={itemsConnectionId}
				parentIssueAri={parentIssueAri}
				parentItemConnectionId={parentItemConnectionId}
				parentIssueRelayConnectionId={parentIssueRelayConnectionId}
				connectionMetadata={connectionMetadata}
				issueSearch={issueSearch}
				isFormVisible={isFormVisible}
				formProps={formProps}
				projectKey={projectKey}
				isGroupingSupported={props.isGroupingSupported}
			/>
		</Placeholder>
	);
};

export const ChildRowsPreloadedQueryEntryPoint = ({
	queries: { issueSearch },
	props,
}: EntryPointProps<Queries, {}, BaseProps, {}>) => (
	<ChildRowsPreloadedQuery {...props} issueSearch={issueSearch} />
);

export type ChildRowsPreloadedQueryProps = BaseProps & {
	issueSearch: PreloadedQuery<hierarchy_childRowsPreloadedQuery>;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-loader/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const ChildRowsPreloadedQuery = (props: ChildRowsPreloadedQueryProps) => {
	const queryData = usePreloadedQuery<hierarchy_childRowsPreloadedQuery>(
		graphql`
			query hierarchy_childRowsPreloadedQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$first: Int
				$last: Int
				$before: String
				$after: String
				$namespace: String
				$viewId: String
				$options: JiraIssueSearchOptions
				$filterId: String
				$amountOfColumns: Int!
				$fieldSetsInput: JiraIssueSearchFieldSetsInput!
				$fieldSetIds: [String!]!
				$shouldQueryFieldSetsById: Boolean!
				$fieldSetsContext: JiraIssueSearchViewFieldSetsContext
				$projectKeys: [String!]
				$projectKey: String
				$viewConfigInput: JiraIssueSearchViewConfigInput
				$shouldQueryHasChildren: Boolean!
				$isReparentingEnabled: Boolean!
			) {
				...hierarchy_nativeIssueTable_ChildRowsPagination_query
					@arguments(
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
						isReparentingEnabled: $isReparentingEnabled
					)
				jira {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						fieldSetsInput: $fieldSetsInput
						viewConfigInput: $viewConfigInput
					) @optIn(to: "JiraSpreadsheetComponent-M1") {
						issueSearchError {
							__typename
							... on JiraServerError {
								message
							}
							... on JiraInvalidSyntaxError {
								message
							}
							... on JiraInvalidJqlError {
								messages
							}
						}
					}
				}
			}
		`,
		props.issueSearch,
	);

	if (props.isGroupingSupported && queryData?.jira?.issueSearch?.issueSearchError?.__typename) {
		switch (queryData.jira.issueSearch.issueSearchError.__typename) {
			case 'JiraInvalidJqlError':
				throw new Error(queryData.jira.issueSearch.issueSearchError.messages?.[0] ?? 'Invalid JQL');
			case 'JiraInvalidSyntaxError':
			case 'JiraServerError':
				throw new Error(queryData.jira.issueSearch.issueSearchError.message ?? 'Unknown Error');
			default:
		}
	}

	return <ChildRowsPagination {...props} query={queryData} />;
};

/**
 * Use '@atlassian/jira-issue-table-hierarchy/src/ui/child-rows-pagination/index.tsx' instead
 * @deprecated delete when jira_list_hierarchy_extraction is cleaned up
 */
export const ChildRowsPagination = ({
	query,
	depth,
	parentItemId,
	itemsConnectionId,
	parentIssueAri,
	parentItemConnectionId,
	parentIssueRelayConnectionId,
	isFormVisible,
	formProps,
	connectionMetadata,
	projectKey,
}: BaseProps & {
	query: hierarchy_nativeIssueTable_ChildRowsPagination_query$key;
}) => {
	const {
		data: queryData,
		loadNext,
		isLoadingNext,
		hasNext,
	} = usePaginationFragment<
		hierarchy_childRowsPreloadedQuery,
		hierarchy_nativeIssueTable_ChildRowsPagination_query$key
	>(
		graphql`
			fragment hierarchy_nativeIssueTable_ChildRowsPagination_query on Query
			@refetchable(queryName: "tableBody_nativeIssueTable_childIssuesPaginationQuery")
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isReparentingEnabled: { type: "Boolean", defaultValue: false }
				isDensityFull: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				jira @required(action: THROW) {
					issueSearch(
						cloudId: $cloudId
						issueSearchInput: $issueSearchInput
						first: $first
						last: $last
						before: $before
						after: $after
						options: $options
						fieldSetsInput: $fieldSetsInput
						viewConfigInput: $viewConfigInput
					)
						@required(action: THROW)
						@connection(key: "hierarchy_nativeIssueTable__issueSearch")
						@optIn(to: "JiraSpreadsheetComponent-M1") {
						__id
						# eslint-disable-next-line @atlassian/relay/unused-fields
						edges {
							__typename # only interested in edge presence to handle empty state
						}
						...hierarchy_nativeIssueTable_ChildRowsPagination_queryData
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isReparentingEnabled: $isReparentingEnabled
								isDensityFull: $isDensityFull
								projectKeys: $projectKeys
								projectKey: $projectKey
								shouldQueryHasChildren: $shouldQueryHasChildren
							)
					}
				}
			}
		`,
		query,
	);

	const issueResultsData =
		useFragment<hierarchy_nativeIssueTable_ChildRowsPagination_queryData$key>(
			graphql`
				fragment hierarchy_nativeIssueTable_ChildRowsPagination_queryData on JiraIssueConnection
				@argumentDefinitions(
					isInlineEditingEnabled: { type: "Boolean!" }
					isReparentingEnabled: { type: "Boolean!" }
					isDensityFull: { type: "Boolean!" }
					projectKeys: { type: "[String!]" }
					projectKey: { type: "String" }
					shouldQueryHasChildren: { type: "Boolean!" }
				) {
					__id
					totalIssueSearchResultCount
					isCappingIssueSearchResult
					edges {
						node {
							id
						}
					}
					...rows_nativeIssueTable
						@arguments(
							isInlineEditingEnabled: $isInlineEditingEnabled
							isReparentingEnabled: $isReparentingEnabled
							isDensityFull: $isDensityFull
							projectKeys: $projectKeys
							projectKey: $projectKey
							shouldQueryHasChildren: $shouldQueryHasChildren
						)
				}
			`,
			queryData?.jira?.issueSearch,
		);

	const { groupId } = useRowContext();
	const { type } = connectionMetadata || {};
	const isGroupChildren = type === 'GROUP_CHILDREN';

	// Set the issue count on the group record if needed
	useUpdateGroupIssueCountOnExpand({
		groupId,
		isGroupChildren,
		totalIssueSearchResultCount: issueResultsData.totalIssueSearchResultCount || 0,
		isCappingIssueSearchResult: issueResultsData.isCappingIssueSearchResult ?? false,
	});

	const loadMoreExperience = isGroupChildren
		? GROUPED_LOAD_MORE_EXPERIENCE
		: CHILD_ITEMS_LOAD_MORE_EXPERIENCE;
	const onExperienceLoadMoreStart = useExperienceStart({
		experience: loadMoreExperience,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceLoadMoreFail = useExperienceFail({
		experience: loadMoreExperience,
		attributes: {},
	});

	const onExperienceLoadMoreSuccess = useExperienceSuccess({
		experience: loadMoreExperience,
		attributes: {},
	});

	const experience = isGroupChildren ? GROUPED_ITEMS_EXPERIENCE : CHILD_ITEMS_EXPERIENCE;
	const onExperienceSuccess = useExperienceSuccess({
		experience,
		attributes: {},
	});

	useEffect(() => {
		onExperienceSuccess();
	}, [onExperienceSuccess]);

	const childConnectionId = issueResultsData.__id;

	const [isSelected] = useIsSelectedNew({
		itemsConnectionId,
		groupId,
		itemId: parentItemId,
	});
	const siblingIssueId = issueResultsData.edges?.[0]?.node?.id;

	const isChildIssueCreateRowVisible =
		isSelected && !!parentIssueAri && !!parentItemConnectionId && !!formProps && isFormVisible;

	if (
		expValEquals('jira_spreadsheet_component_m1', 'cohort', 'experiment') &&
		expVal('jira_list_realtime_updates', 'isEnabled', false)
	) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useUpdateHierarchyHasChildrenOnExpand({
			connectionMetadata,
			projectKey,
			childCount: issueResultsData.edges?.length ?? 0,
		});
	}

	if (fg('decouple_connection_register_hooks_from_realtime')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const connectionDetails = useMemo(() => {
			if (
				issueResultsData?.edges?.length === undefined ||
				(issueResultsData.edges.length === 0 && !isChildIssueCreateRowVisible) ||
				!connectionMetadata
			) {
				return undefined;
			}

			return {
				connectionId: childConnectionId,
				// This is the value the backend uses to fetch the issue list for realtime updates. It should be the connection length + 1
				// to ensure a potentially newly created issue is also fetched and included in the results.
				first: issueResultsData.edges.length + 1,
				...connectionMetadata,
			};
		}, [
			childConnectionId,
			connectionMetadata,
			isChildIssueCreateRowVisible,
			issueResultsData.edges?.length,
		]);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useRegisterConnection(connectionDetails);
	}

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCompleteLoadNextItems = useCallback(
		(error: Error | null) => {
			if (!error) {
				onExperienceLoadMoreSuccess();
			} else {
				onExperienceLoadMoreFail(PACKAGE_LOCATION, error);
			}
		},
		[onExperienceLoadMoreFail, onExperienceLoadMoreSuccess],
	);

	const loadNextItems = useCallback(() => {
		onExperienceLoadMoreStart();
		const actionSubjectId = isGroupChildren ? 'groupingLoadMore' : 'hierarchyLoadMore';
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', actionSubjectId);
		loadNext(CHILD_QUERY_PAGE_SIZE, { onComplete: onCompleteLoadNextItems });
	}, [
		onExperienceLoadMoreStart,
		isGroupChildren,
		createAnalyticsEvent,
		loadNext,
		onCompleteLoadNextItems,
	]);

	const renderChildIssueCreateRow = useCallback(() => {
		if (!isChildIssueCreateRowVisible) {
			return null;
		}
		return (
			<ChildIssueCreateRow
				depth={depth}
				parentItemConnectionId={parentItemConnectionId}
				parentIssueAri={parentIssueAri}
				itemsConnectionId={childConnectionId}
				siblingIssueId={siblingIssueId}
				isFormVisible={isFormVisible ?? false}
				{...formProps}
			/>
		);
	}, [
		childConnectionId,
		depth,
		formProps,
		isChildIssueCreateRowVisible,
		isFormVisible,
		parentIssueAri,
		parentItemConnectionId,
		siblingIssueId,
	]);

	return (
		<>
			<Rows
				issueResults={issueResultsData}
				depth={depth + 1}
				parentItemId={parentItemId}
				itemsConnectionId={itemsConnectionId}
				parentIssueAri={parentIssueAri}
				parentItemConnectionId={parentItemConnectionId}
				parentIssueRelayConnectionId={parentIssueRelayConnectionId}
				isLoadingNext={isLoadingNext}
				hasNext={hasNext}
				onLoadNextItems={loadNextItems}
				renderChildIssueCreateRow={renderChildIssueCreateRow}
				isChildIssueCreateRowVisible={isChildIssueCreateRowVisible}
				{...(fg('jsc_list_reparenting') ? { projectKey } : {})}
			/>
		</>
	);
};
