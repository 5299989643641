/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { graphql, useFragment } from 'react-relay';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { cssMap, cx, jsx } from '@atlaskit/css';
import DragHandleVerticalIcon from '@atlaskit/icon/utility/drag-handle-vertical';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { token } from '@atlaskit/tokens';
import type { OnSubmit } from '@atlassian/jira-assign-issue-parent-modal/src/model/types.tsx';
import { JiraIssueAri } from '@atlassian/ari/jira';
import { ModalEntryPointDropdownItemTrigger } from '@atlassian/jira-modal-entry-point-dropdown-item-trigger/src/ModalEntryPointDropdownItemTrigger.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { dragActionsButton_nativeIssueTable$key } from '@atlassian/jira-relay/src/__generated__/dragActionsButton_nativeIssueTable.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { getItemKey } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import {
	useIsReparentingEnabled,
	useIsRowReorderingEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { ROW_HEIGHT, ROW_OPERATION_REORDER } from '../../../../common/constants.tsx';
import { RowDropIndicator } from '../../../../common/ui/row-drop-indicator/index.tsx';
import { RowPopupButton } from '../../../../common/ui/row-popup-button/index.tsx';
import { useDraggableRows } from '../../../../controllers/draggable-rows/index.tsx';
import type {
	DraggableRowData,
	DropTarget,
	DraggableRowProps,
	DropEdgeTarget,
} from '../../../../controllers/draggable-rows/types.tsx';
import { useReparentingFromModal } from '../../../../controllers/reparenting/index.tsx';
import { reparentModalEntryPoint } from '../../../reparent-modal/entrypoint.tsx';
import { ReparentingOnboarding } from './reparenting-onboarding/index.tsx';
import { DragPreview } from './drag-preview/index.tsx';
import messages from './messages.tsx';

const styles = cssMap({
	button: {
		height: '16px',
		width: '14px',
		borderStyle: 'solid',
		borderColor: token('color.border'),
		borderWidth: token('border.width'),
		boxShadow: token('elevation.shadow.overlay'),
		backgroundColor: token('elevation.surface.raised'),
		color: token('color.text'),
		// Change to 'pointer' if we choose to implement issue actions dropdown
		cursor: 'grab',
		'&:hover': {
			backgroundColor: token('elevation.surface.raised.hovered'),
		},
		'&:active': {
			backgroundColor: token('color.background.accent.blue.subtle.pressed'),
			color: token('color.link.pressed'),
			cursor: 'grabbing',
		},
	},
});

// Negatively offset by 1px to account for the cell border
const offset: [number, number] = [ROW_HEIGHT / 2 - 1, -1];

const BUTTON_SIZE = 16;

const IDLE_DRAGGABLE_STATE = 'idle';
const PREVIEW_DRAGGABLE_STATE = 'preview';
const DRAGGING_DRAGGABLE_STATE = 'dragging';

type DragButtonState = typeof IDLE_DRAGGABLE_STATE | typeof DRAGGING_DRAGGABLE_STATE;

// TODO: Remove when cleaning jsc_list_reparenting
const isDropEdgeTarget = (dropTarget: DropTarget): dropTarget is DropEdgeTarget =>
	'dropEdge' in dropTarget;

export type Props = DraggableRowProps & {
	/** Issue key of the issue to reparent  */
	issueKey?: string | null | undefined;
	/** Key of the current project */
	projectKey?: string | undefined;
	/** Boolean to know if the row is hovered and then show the drag handler  */
	isRowHovered?: boolean;
	firstCellData: dragActionsButton_nativeIssueTable$key | null;
	setCurrentDraggingRow?: (itemKey: string | null) => void;
};

type DraggableState =
	| { type: typeof IDLE_DRAGGABLE_STATE }
	| { type: typeof PREVIEW_DRAGGABLE_STATE; container: HTMLElement }
	| { type: typeof DRAGGING_DRAGGABLE_STATE };

export const DragActionsButton = ({
	edgeIndex,
	issueId,
	hierarchyLevel,
	parentIssueAri,
	parentIssueConnectionId,
	issueKey,
	projectKey,
	isRowHovered,
	firstCellData,
	itemsConnectionId,
	setCurrentDraggingRow,
}: Props) => {
	const dragPreviewData = useFragment<dragActionsButton_nativeIssueTable$key>(
		graphql`
			fragment dragActionsButton_nativeIssueTable on JiraIssue
			@argumentDefinitions(isReparentingEnabled: { type: "Boolean!" }) {
				summary @include(if: $isReparentingEnabled)
				issueTypeField @include(if: $isReparentingEnabled) {
					issueType {
						avatar {
							small
						}
					}
				}
			}
		`,
		firstCellData,
	);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [buttonState, setButtonState] = useState<DragButtonState>(IDLE_DRAGGABLE_STATE);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

	const [{ currentDropTarget, instanceId, items }, { reorderRows }] = useDraggableRows();
	const isRowReorderingEnabled = useIsRowReorderingEnabled();
	const isReparentingEnabled = useIsReparentingEnabled();

	const { formatMessage } = useIntl();

	const isVisible = isDropdownOpen || isRowHovered || buttonState === DRAGGING_DRAGGABLE_STATE;
	const isDropTarget = currentDropTarget?.issueId === issueId;

	const [draggableState, setDraggableState] = useState<DraggableState>({
		type: IDLE_DRAGGABLE_STATE,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();

	useEffect(() => {
		if (!buttonRef.current) return;

		return draggable({
			element: buttonRef.current,
			getInitialData(): DraggableRowData {
				return {
					operation: ROW_OPERATION_REORDER,
					instanceId,
					issueId,
					rowIndex: edgeIndex,
					hierarchyLevel,
					parentIssueAri,
					parentIssueConnectionId,
					itemsConnectionId,
				};
			},
			onGenerateDragPreview({ nativeSetDragImage }) {
				// We need to make sure that the element not obfuscated by the sticky header
				setCustomNativeDragPreview({
					nativeSetDragImage,
					getOffset: pointerOutsideOfPreview({
						x: token('space.250'),
						y: token('space.250'),
					}),
					render({ container }) {
						setDraggableState({ type: PREVIEW_DRAGGABLE_STATE, container });
						return () => setDraggableState({ type: IDLE_DRAGGABLE_STATE });
					},
				});
			},
			onDragStart() {
				if (isReparentingEnabled && itemsConnectionId && fg('jsc_list_reparenting')) {
					const itemKey = getItemKey(itemsConnectionId, issueId);
					setCurrentDraggingRow && setCurrentDraggingRow(itemKey);
				}

				setButtonState('dragging');
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'dragged',
						actionSubject: 'dragActionsButton',
						actionSubjectId: 'jscDraggableIssue',
					}),
				);
			},
			onDrop() {
				if (isReparentingEnabled && fg('jsc_list_reparenting')) {
					setCurrentDraggingRow && setCurrentDraggingRow(null);
				}
				buttonRef.current?.blur();
				setButtonState('idle');
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'dropped',
						actionSubject: 'dragActionsButton',
						actionSubjectId: 'jscDraggableIssue',
					}),
				);
			},
		});
	}, [
		createAnalyticsEvent,
		edgeIndex,
		issueId,
		hierarchyLevel,
		parentIssueAri,
		instanceId,
		itemsConnectionId,
		parentIssueConnectionId,
		setCurrentDraggingRow,
		isReparentingEnabled,
	]);

	const handleClick = useCallback(() => {
		const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		if (parentIssueAri) {
			fireUIAnalytics(analyticsEvent, 'changeParent');
		} else {
			fireUIAnalytics(analyticsEvent, 'addParent');
		}
	}, [createAnalyticsEvent, parentIssueAri]);

	const isFirstRow = edgeIndex === 0 && !parentIssueAri;
	const [isOnboardingVisible, setIsOnboardingVisible] = useState(false);

	let handleSubmitReparenting: undefined | OnSubmit;
	if (fg('jsc_list_reparenting')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleSubmitReparenting = useReparentingFromModal({ projectKey });
	}

	const modalEntryPointProps = useMemo(
		() => ({
			issueId,
			parentIssueAri,
			issueKey,
			hierarchyLevel,
			onSubmit: handleSubmitReparenting,
		}),
		[handleSubmitReparenting, hierarchyLevel, issueId, issueKey, parentIssueAri],
	);

	if (!issueKey && isReparentingEnabled && fg('jsc_list_reparenting')) {
		// if the issue key is empty, it indicates that it’s an optimistic row so we should not render the drag handler
		return null;
	}

	return (
		<>
			<DropdownMenu
				isOpen={isDropdownOpen}
				onOpenChange={(attrs) => {
					setIsDropdownOpen(attrs.isOpen);
				}}
				trigger={({ triggerRef, ...triggerProps }) => (
					<RowPopupButton
						alignToYAxis
						buttonSize={BUTTON_SIZE}
						icon={<DragHandleVerticalIcon label="" />}
						isVisible={isVisible || isOnboardingVisible}
						label={formatMessage(messages.label)}
						offset={offset}
						ref={mergeRefs(triggerRef, buttonRef)}
						xcss={cx(styles.button)}
						onClick={triggerProps.onClick}
					/>
				)}
				testId="native-issue-table.ui.row.before-first-cell.drag-actions-button.dropdown-menu"
			>
				{isRowReorderingEnabled && items.size > 1 && (
					<DropdownItemGroup data-testid="native-issue-table.ui.row.before-first-cell.drag-actions-button.reorder-menu-items">
						<DropdownItem
							isDisabled={edgeIndex === 0}
							onClick={() => {
								reorderRows(edgeIndex, edgeIndex - 1);
							}}
						>
							{formatMessage(messages.moveUp)}
						</DropdownItem>
						<DropdownItem
							isDisabled={edgeIndex === items.size - 1}
							onClick={() => {
								reorderRows(edgeIndex, edgeIndex + 1);
							}}
						>
							{formatMessage(messages.moveDown)}
						</DropdownItem>
					</DropdownItemGroup>
				)}

				{projectKey && fg('jsc_list_reparenting') && (
					<DropdownItemGroup>
						<ModalEntryPointDropdownItemTrigger
							entryPoint={reparentModalEntryPoint}
							entryPointProps={modalEntryPointProps}
							entryPointParams={{
								cloudId,
								projectKey,
							}}
							interactionName="open-reparent-issue-modal"
							useInternalModal
							onClick={handleClick}
						>
							{parentIssueAri
								? formatMessage(messages.changeParent)
								: formatMessage(messages.addParent)}
						</ModalEntryPointDropdownItemTrigger>
					</DropdownItemGroup>
				)}
			</DropdownMenu>
			{isDropTarget && (
				<RowDropIndicator
					{...(isDropEdgeTarget(currentDropTarget)
						? {
								edge: currentDropTarget.dropEdge,
							}
						: { instruction: currentDropTarget.dropInstruction })}
				/>
			)}
			{draggableState.type === PREVIEW_DRAGGABLE_STATE &&
				isReparentingEnabled &&
				dragPreviewData &&
				createPortal(
					<DragPreview
						issueId={JiraIssueAri.parse(issueId).issueId}
						itemsConnectionId={itemsConnectionId}
						summary={dragPreviewData.summary}
						issueTypeAvatarUrl={dragPreviewData.issueTypeField?.issueType?.avatar?.small}
					/>,
					draggableState.container,
				)}
			{isFirstRow && isReparentingEnabled && buttonRef.current && (
				<ReparentingOnboarding
					buttonElement={buttonRef.current}
					setIsOnboardingVisible={setIsOnboardingVisible}
				/>
			)}
		</>
	);
};
