import React, { memo } from 'react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';
import { DropIndicator as EdgeDropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import type { Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useScrollStateSelector } from '../../../controllers/scroll-state/index.tsx';
import { useIsReparentingEnabled } from '../../../controllers/features/selectors.tsx';
import { REORDER_ABOVE } from '../../constants.tsx';
import { EXTRA_SPACE_PER_LEVEL } from '../../../controllers/draggable-rows/index.tsx';

type Props = {
	/** The `edge` to draw a drop indicator on. See https://atlassian.design/components/pragmatic-drag-and-drop/optional-packages/react-drop-indicator/about#edge. */
	edge?: Edge;
	instruction?: Instruction;
};

export const RowDropIndicatorNew = ({ instruction }: { instruction: Instruction }) => {
	const width = useScrollStateSelector((scrollState) => scrollState.width - EXTRA_SPACE_PER_LEVEL);

	const isReParentingEnabled = useIsReparentingEnabled();

	return (
		<Box
			testId="native-issue-table.common.ui.row-drop-indicator.box"
			xcss={[boxStyles, dropIndicatorStyles]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ width: `${width}px` }}
			data-row-drop-indicator
		>
			{isReParentingEnabled ? (
				<DropIndicator instruction={instruction} />
			) : (
				<EdgeDropIndicator edge={instruction.type === REORDER_ABOVE ? 'top' : 'bottom'} />
			)}
		</Box>
	);
};

export const RowDropIndicatorOld = ({ edge }: { edge?: Edge }) => {
	const width = useScrollStateSelector((scrollState) => scrollState.width);

	return (
		<Box
			testId="native-issue-table.common.ui.row-drop-indicator.box"
			xcss={[boxStyles]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ width: `${width}px` }}
			data-row-drop-indicator
		>
			{edge && <EdgeDropIndicator edge={edge} />}
		</Box>
	);
};

export const RowDropIndicator = memo(({ edge, instruction }: Props) => {
	if (instruction && fg('jsc_list_reparenting')) {
		return <RowDropIndicatorNew instruction={instruction} />;
	}

	return <RowDropIndicatorOld edge={edge} />;
});

const boxStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	bottom: 'space.0',
	// Negatively offset by 1px to account for the cell border
	left: '-1px',
	pointerEvents: 'none',
});

const dropIndicatorStyles = xcss({
	left: 'space.050',
	width: '100%',
	zIndex: 'tooltip',
});
