/** @jsx jsx */
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Inline, xcss } from '@atlaskit/primitives';
import ShowMorePopup, { type Props, type MinimumItemData } from './show-more-popup/index.tsx';

const ListWithPopup = <TData extends MinimumItemData>({
	items,
	ItemComponent,
	maxLimit,
	initialIsOpen,
	isHoverPopoverEnabled,
	...restProps
}: Props<TData>) => {
	if (items.length === 0) {
		return null;
	}

	const [firstItem, ...restItems] = items;

	return (
		<Inline
			space="space.100"
			alignBlock="center"
			shouldWrap={!isHoverPopoverEnabled}
			xcss={isHoverPopoverEnabled && hoverPopoverStyles}
		>
			<ItemComponent
				{...firstItem}
				isHoverPopoverEnabled={isHoverPopoverEnabled}
				css={isHoverPopoverEnabled && hoverPopoverItemStyles}
			/>
			{restItems.length >= 1 && (
				<ShowMorePopup
					items={restItems}
					maxLimit={maxLimit}
					ItemComponent={ItemComponent}
					initialIsOpen={initialIsOpen}
					isHoverPopoverEnabled={isHoverPopoverEnabled}
					{...restProps}
				/>
			)}
		</Inline>
	);
};

const hoverPopoverStyles = xcss({
	paddingRight: 'space.050',
});

const hoverPopoverItemStyles = css({
	minWidth: token('space.0'),
});

export default ListWithPopup;
