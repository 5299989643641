import React, { memo, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { OPERATOR_EQUALS, creators, print } from '@atlaskit/jql-ast';
import { Inline } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { LabelsInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-labels-inline-edit-view-full/src/ui/labels/index.tsx';
import ListCellWithPopup from '@atlassian/jira-list-with-popup/src/ui/main.tsx';
import {
	TagItem,
	TagItemPopupContentWrapper,
} from '@atlassian/jira-list-with-popup/src/ui/tag-item/index.tsx';
import { KNOWN_COLUMN_TYPES } from '@atlassian/jira-issue-table-common/src/constants.tsx';
import type { labels_nativeIssueTable_LabelsCell$key } from '@atlassian/jira-relay/src/__generated__/labels_nativeIssueTable_LabelsCell.graphql';
import type { labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/labels_nativeIssueTable_LabelsInlineEditCell_fieldRef.graphql';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useIsDensityFull,
	useIsInlineEditingExtendedFieldSupportEnabled,
	useIsSingleLineRowHeightEnabled,
} from '../../../../controllers/features/selectors.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { escapeBackslashes, isNonNullish } from '../../../utils/index.tsx';
import ErrorCell from '../../error-cell/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import messages from './messages.tsx';

export type Props = {
	fieldRef: labels_nativeIssueTable_LabelsCell$key;
	issueSearchBaseUrl: string;
	jqlTerm: string;
};

/**
 * Expected JQL Output: `${jqlTerm} = ${name}`
 */
const getLinkJql = (jqlTerm: string, name: string) => {
	const jast = creators.jast(
		creators.query(
			creators.terminalClause(
				creators.field(escapeBackslashes(jqlTerm)),
				creators.operator(OPERATOR_EQUALS),
				creators.valueOperand(escapeBackslashes(name)),
			),
		),
	);

	return print(jast, {
		operatorCase: 'upper',
	});
};

export const LabelsCell = memo<Props>(({ fieldRef, issueSearchBaseUrl, jqlTerm }: Props) => {
	const { formatMessage } = useIntl();

	/* eslint-disable @atlassian/relay/graphql-naming */
	const data = useFragment<labels_nativeIssueTable_LabelsCell$key>(
		graphql`
			fragment labels_nativeIssueTable_LabelsCell on JiraLabelsField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				fieldId
				selectedLabelsConnection(first: 1000) {
					... on JiraLabelConnection {
						edges {
							node {
								name
							}
						}
					}
				}
				...labels_nativeIssueTable_LabelsInlineEditCell_fieldRef
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
			}
		`,
		fieldRef,
	);
	/* eslint-enable @atlassian/relay/graphql-naming */

	const isDensityFull = useIsDensityFull();
	const isSingleLineRowHeightEnabled = useIsSingleLineRowHeightEnabled();

	const isLabelsSystemField = data.fieldId === KNOWN_COLUMN_TYPES.LABELS;
	const labels = data.selectedLabelsConnection?.edges;
	const filteredLabels = useMemo(
		() =>
			isLabelsSystemField
				? []
				: labels
						?.map((edge) =>
							edge?.node?.name != null
								? {
										key: edge.node.name,
										name: edge.node.name,
										href: `${issueSearchBaseUrl}?jql=${encodeURIComponent(
											getLinkJql(jqlTerm, edge.node.name),
										)}`,
										...(expVal(
											'adding-color-to-labels-field-in-jira-experiment',
											'isColoredLabelsEnabled',
											false,
										)
											? { isColored: true }
											: {}),
									}
								: null,
						)
						.filter(isNonNullish) ?? [],
		[isLabelsSystemField, labels, issueSearchBaseUrl, jqlTerm],
	);

	if (isLabelsSystemField || fg('jsc_inline_editing_field_refactor')) {
		return (
			<LabelsInlineEditCell
				fieldRef={data}
				issueSearchBaseUrl={issueSearchBaseUrl}
				isTruncated={!isDensityFull}
				isHoverPopoverEnabled={isSingleLineRowHeightEnabled}
			/>
		);
	}

	if (!labels || labels.length !== filteredLabels.length) {
		return <ErrorCell cellType="labels" reason="Missing required fields" />;
	}

	if (isDensityFull) {
		return (
			<Inline space="space.100" alignBlock="center" shouldWrap>
				{filteredLabels.map((label) => (
					<TagItem
						key={label.key}
						name={label.name}
						href={label.href}
						{...(label.isColored ? { isColored: label.isColored } : {})}
					/>
				))}
			</Inline>
		);
	}

	return (
		<ListCellWithPopup
			items={filteredLabels}
			ItemComponent={TagItem}
			PopupContentWrapper={TagItemPopupContentWrapper}
			showMoreTooltip={formatMessage(messages.triggerButton)}
		/>
	);
});

type LabelsInlineEditCellProps = {
	fieldRef: labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$key;
	issueSearchBaseUrl: string;
	isTruncated: boolean;
	/** Whether the menu should use a portal, and where it should attach */
	menuPortalTarget?: HTMLElement;
	isHoverPopoverEnabled: boolean;
};

export const LabelsInlineEditCell = ({
	fieldRef,
	issueSearchBaseUrl,
	isTruncated,
	menuPortalTarget,
	isHoverPopoverEnabled,
}: LabelsInlineEditCellProps) => {
	const data = useFragment<labels_nativeIssueTable_LabelsInlineEditCell_fieldRef$key>(
		graphql`
			fragment labels_nativeIssueTable_LabelsInlineEditCell_fieldRef on JiraLabelsField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				fieldId
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isLabelsSystemField = data.fieldId === KNOWN_COLUMN_TYPES.LABELS;
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	const isInlineEditingSupported =
		isLabelsSystemField || isInlineEditingExtendedFieldSupportEnabled;
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingSupported
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<LabelsInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			baseSearchUrl={issueSearchBaseUrl}
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isTruncated={isTruncated}
			isEditable={isEditable ?? false}
			menuPosition="fixed"
			menuPortalTarget={menuPortalTarget}
			readViewFitContainerHeight
			isHoverPopoverEnabled={isHoverPopoverEnabled}
		/>
	);
};
