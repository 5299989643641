import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
	graphql,
	usePaginationFragment,
	usePreloadedQuery,
	type EntryPointProps,
} from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { IssueTypeEditViewWithIssueTypeConnection } from '@atlassian/jira-issue-field-issue-type-editview-full/src/ui/issue-type/index.tsx';
import type { Option } from '@atlassian/jira-issue-field-issue-type-editview-full/src/ui/issue-type/types.tsx';
import issueTypeDropdownInlineIssueCreateRefetchPaginatedQuery, {
	type issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query,
} from '@atlassian/jira-relay/src/__generated__/issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query.graphql';
import type { issueTypeDropdown_issueTableInlineIssueCreate$key } from '@atlassian/jira-relay/src/__generated__/issueTypeDropdown_issueTableInlineIssueCreate.graphql';
import type { issueTypeDropdownIssueType_projectDataQuery } from '@atlassian/jira-relay/src/__generated__/issueTypeDropdownIssueType_projectDataQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueCreateIssueType, IssueCreateProject } from '../../../common/types.tsx';
import { useDefaultIssueType } from '../../../controllers/config/selectors.tsx';
import messages from './messages.tsx';

const createIssueTypeOption = (
	id: string,
	name: string,
	avatar: string | null | undefined,
): Option => ({
	label: name,
	value: id,
	avatar: {
		small: avatar,
	},
});

const createIssueType = (
	id: string,
	issueTypeId: string,
	name: string,
	avatar: string | null | undefined,
	hierarchyLevel: number | null | undefined,
): IssueCreateIssueType => ({
	id,
	issueTypeId,
	name,
	avatar: {
		small: avatar,
	},
	...(typeof hierarchyLevel === 'number' ? { hierarchy: { level: hierarchyLevel } } : {}),
});

export type Props = {
	onChange: (selectedType: IssueCreateIssueType | null) => void;
	onProjectChange: (project: IssueCreateProject | null) => void;
	selectedType: IssueCreateIssueType | null;
	project: issueTypeDropdown_issueTableInlineIssueCreate$key | null;
};

export type IssueTypeOption = Option;

// TODO: Create a base component and extend the base to have different UI components -- One with only icons
// for select value and one with both icons and labels for the select value.
export const IssueTypeDropdown = ({ onChange, onProjectChange, project, selectedType }: Props) => {
	const { formatMessage } = useIntl();
	const isPristine = useRef(true);

	// TODO; JSC-36  We need to integrate hierarchy level filter once JSC-59 is implemented
	const { data, refetch } = usePaginationFragment<
		issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query,
		issueTypeDropdown_issueTableInlineIssueCreate$key
	>(
		graphql`
			fragment issueTypeDropdown_issueTableInlineIssueCreate on JiraProject
			@argumentDefinitions(
				cursor: { type: "String" }
				first: { type: "Int", defaultValue: 10 }
				filter: { type: "JiraIssueTypeFilterInput" }
			)
			@refetchable(queryName: "issueTypeDropdown_InlineIssueCreate_RefetchPaginated_Query") {
				id
				projectId
				key
				name
				avatar {
					large
				}
				issueTypes(first: $first, after: $cursor, filter: $filter)
					@connection(key: "inlineIssueCreate_IssueTable_issueTypes") {
					edges {
						node {
							id
							issueTypeId
							name
							avatar {
								small
							}
							hierarchy {
								level
							}
						}
					}
					...issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef
				}
			}
		`,
		project,
	);

	const [defaultIssueTypeId, { setDefaultIssueType }] = useDefaultIssueType(data?.key ?? null);

	const selectedTypeOption = useMemo<IssueTypeOption | null>(() => {
		if (selectedType == null) {
			return null;
		}
		return createIssueTypeOption(selectedType.id, selectedType.name, selectedType.avatar?.small);
	}, [selectedType]);

	const handleGetRefetchVariables = useCallback(
		() => ({
			id: data?.id || '',
		}),
		[data?.id],
	);

	const handleIssueTypeChange = useCallback(
		(typeOption: IssueTypeOption | null) => {
			let newIssueType: IssueCreateIssueType | null = null;
			if (typeOption != null) {
				const issueType = (data?.issueTypes?.edges || []).find(
					(edge) => edge?.node?.id === typeOption.value,
				);
				if (issueType?.node != null && issueType.node.issueTypeId != null) {
					newIssueType = createIssueType(
						issueType.node.id,
						issueType.node.issueTypeId,
						issueType.node.name,
						issueType.node.avatar?.small,
						issueType.node.hierarchy?.level,
					);
				}
			}
			isPristine.current = false;

			onChange(newIssueType);
			if (newIssueType?.issueTypeId) {
				setDefaultIssueType(data?.key ?? null, newIssueType?.issueTypeId);
			}
		},
		[data?.issueTypes?.edges, data?.key, onChange, setDefaultIssueType],
	);

	// Initialize with the first default issue
	useEffect(() => {
		if (isPristine.current) {
			const defaultIssueTypeEdge = (data?.issueTypes?.edges || []).find(
				(edge) => edge?.node?.issueTypeId === defaultIssueTypeId,
			);

			if (defaultIssueTypeEdge?.node != null && defaultIssueTypeEdge.node.issueTypeId != null) {
				// Return default issue type if exists
				return onChange(
					createIssueType(
						defaultIssueTypeEdge.node.id,
						defaultIssueTypeEdge.node.issueTypeId,
						defaultIssueTypeEdge.node.name,
						defaultIssueTypeEdge.node.avatar?.small,
						defaultIssueTypeEdge.node.hierarchy?.level,
					),
				);
			}
			const firstIssue = data?.issueTypes?.edges?.[0];
			if (firstIssue?.node != null && firstIssue.node.issueTypeId != null) {
				// Return first issue type in the connection
				return onChange(
					createIssueType(
						firstIssue.node.id,
						firstIssue.node.issueTypeId,
						firstIssue.node.name,
						firstIssue.node.avatar?.small,
						firstIssue.node.hierarchy?.level,
					),
				);
			}
		}
	}, [data?.issueTypes?.edges, defaultIssueTypeId, onChange]);

	const prevProjectKey = useRef<string | null>(null);

	useEffect(() => {
		if (data?.id != null && prevProjectKey.current !== data?.id && data.projectId != null) {
			onProjectChange({
				id: data.id,
				projectId: data.projectId,
				key: data.key,
				name: data.name,
				avatar: data.avatar
					? {
							large: data.avatar.large,
						}
					: null,
			});
			prevProjectKey.current = data?.id;
		}
	}, [data?.avatar, data?.id, data?.key, data?.name, data?.projectId, onProjectChange]);

	// TODO: JSC-71 We need to updated below component styles to adapt more to JWM current design
	return (
		<IssueTypeEditViewWithIssueTypeConnection
			issueTypesFragmentRef={data?.issueTypes ?? null}
			menuPosition="fixed"
			menuPlacement={fg('jsc_fix_issue_type_dropdown_positioning') ? 'top' : undefined}
			onChange={handleIssueTypeChange}
			onRefetch={refetch}
			onGetRefetchVariables={handleGetRefetchVariables}
			refetchQuery={issueTypeDropdownInlineIssueCreateRefetchPaginatedQuery}
			value={selectedTypeOption}
			spacing="compact"
			stopPropagationOfMenuEscape
			label={formatMessage(messages.label)}
			iconsOnly={expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)}
			{...(expVal('jira_spreadsheet_component_m1', 'isInlineIssueCreateEnabled', false)
				? { appearance: 'subtle' }
				: {})}
			isSearchable={!fg('fix_icc_type_select_being_searchable')}
		/>
	);
};

type Queries = {
	projectData: issueTypeDropdownIssueType_projectDataQuery;
};

export const IssueTypeDropdownEntryPoint = ({
	queries: { projectData },
	props,
}: EntryPointProps<Queries, {}, Omit<Props, 'project'>, {}>) => {
	const data = usePreloadedQuery<issueTypeDropdownIssueType_projectDataQuery>(
		graphql`
			query issueTypeDropdownIssueType_projectDataQuery(
				$cloudId: ID!
				$projectKey: String!
				$hasProjectKey: Boolean!
				$filter: JiraIssueTypeFilterInput
			) {
				jira @required(action: THROW) {
					jiraProjectByKey(cloudId: $cloudId, key: $projectKey)
						@required(action: THROW)
						@include(if: $hasProjectKey) {
						...issueTypeDropdown_issueTableInlineIssueCreate @arguments(filter: $filter)
					}
				}
			}
		`,
		projectData,
	);

	return <IssueTypeDropdown {...props} project={data.jira.jiraProjectByKey ?? null} />;
};
export default IssueTypeDropdown;
