/**
 * @generated SignedSource<<75c642c02e833396c7093e45ac0232ab>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueTable_nativeIssueTable_groupResults$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"groupTableBody_nativeIssueTable_groupResults">;
  readonly " $fragmentType": "issueTable_nativeIssueTable_groupResults";
};
export type issueTable_nativeIssueTable_groupResults$key = {
  readonly " $data"?: issueTable_nativeIssueTable_groupResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_groupResults">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPaginating"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "issueTable_nativeIssueTable_groupResults",
  "selections": [
    {
      "concreteType": "JiraSpreadsheetGroupEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraSpreadsheetGroup",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "groupTableBody_nativeIssueTable_groupResults"
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "a8e567e9b1565d866f8267eab959ee68";

export default node;
