import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { DateTimeInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-date-time-inline-edit-full/src/ui/date-time/index.tsx';
import type { dateTime_nativeIssueTable_DateTimeCell$key } from '@atlassian/jira-relay/src/__generated__/dateTime_nativeIssueTable_DateTimeCell.graphql';
import { KNOWN_COLUMN_TYPES } from '@atlassian/jira-issue-table-common/src/constants.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: dateTime_nativeIssueTable_DateTimeCell$key;
};

export const DateTimeCell = ({ fieldRef }: Props) => {
	const data = useFragment<dateTime_nativeIssueTable_DateTimeCell$key>(
		graphql`
			fragment dateTime_nativeIssueTable_DateTimeCell on JiraDateTimePickerField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				type
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isCreatedOrUpdated =
		data.type === KNOWN_COLUMN_TYPES.CREATED || data.type === KNOWN_COLUMN_TYPES.UPDATED;
	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	const isInlineEditingSupported =
		!isCreatedOrUpdated && isInlineEditingExtendedFieldSupportEnabled;
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	// We can also disable editability of the created/updated fields as they are never editable.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingSupported
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<DateTimeInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
			isAutoFocusFixEnabled
		/>
	);
};
