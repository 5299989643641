import { useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useRelayEnvironment } from 'react-relay';
import { ConnectionHandler, commitLocalUpdate, getRelayHandleKey } from 'relay-runtime';
import type { firstGroupIssues_childIssuesQuery$variables } from '@atlassian/jira-relay/src/__generated__/firstGroupIssues_childIssuesQuery.graphql';
import { useHierarchyActions } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';

const JIRA = 'jira';
const HIERARCHY_ISSUE_SEARCH_CONNECTION = 'hierarchy_nativeIssueTable__issueSearch';
const JIRA_ISSUE_CONNECTION_TYPE = 'JiraIssueConnection';
const CONNECTION = 'connection';

type FieldSetsInput = firstGroupIssues_childIssuesQuery$variables['fieldSetsInput'];
type ViewConfigInput = firstGroupIssues_childIssuesQuery$variables['viewConfigInput'];

export type Props = {
	groupId: string;
	groupConnectionId: string;
	firstGroupItemsConnectionId: string | null;
	issueCount?: number | null;
	loading: boolean;
	issueSearchVariables: Pick<
		firstGroupIssues_childIssuesQuery$variables,
		'cloudId' | 'issueSearchInput' | 'fieldSetsInput' | 'viewConfigInput'
	>;
};

export const useInitialiseFirstGroupIssues = ({
	groupId,
	groupConnectionId,
	firstGroupItemsConnectionId,
	issueSearchVariables,
	loading,
}: Props) => {
	const environment = useRelayEnvironment();
	const hasInitialised = useRef(false);
	const prevGroupConnectionId = useRef<string>(groupConnectionId);
	const prevFieldSetsInput = useRef<FieldSetsInput>(issueSearchVariables.fieldSetsInput);
	const prevViewConfigInput = useRef<ViewConfigInput>(issueSearchVariables.viewConfigInput);
	const prevLoading = useRef<boolean>(loading);

	const { setIsItemExpanded } = useHierarchyActions();

	// When firstGroupItemsConnectionId is available, if the groupConnectionId changes, fieldSetsInput change or view has finished loading, trigger re-initialisation
	if (
		!!firstGroupItemsConnectionId &&
		((!loading && prevLoading.current) ||
			prevGroupConnectionId.current !== groupConnectionId ||
			!isEqual(prevFieldSetsInput.current, issueSearchVariables.fieldSetsInput) ||
			!isEqual(prevViewConfigInput.current, issueSearchVariables.viewConfigInput))
	) {
		hasInitialised.current = false;
		prevGroupConnectionId.current = groupConnectionId;
		prevFieldSetsInput.current = issueSearchVariables.fieldSetsInput;
		prevViewConfigInput.current = issueSearchVariables.viewConfigInput;
		prevLoading.current = loading;
	}

	if (!hasInitialised.current) {
		setIsItemExpanded(
			{
				itemId: groupId,
				groupId,
				itemsConnectionId: groupConnectionId,
			},
			true,
		);

		commitLocalUpdate(environment, (store) => {
			const firstGroupIssuesConnection = store.get(firstGroupItemsConnectionId ?? '');
			const jiraRecord = store.getRoot().getLinkedRecord(JIRA);
			if (!firstGroupIssuesConnection || !jiraRecord) {
				return;
			}

			const connectionId = ConnectionHandler.getConnectionID(
				jiraRecord.getDataID(),
				HIERARCHY_ISSUE_SEARCH_CONNECTION,
				issueSearchVariables,
			);

			// If connection already exists, update it with the latest data. This ensures the field sets are correct.
			let connection = store.get(connectionId);
			if (!connection) {
				connection = store.create(connectionId, JIRA_ISSUE_CONNECTION_TYPE);
			}
			connection.copyFieldsFrom(firstGroupIssuesConnection);
			jiraRecord.setLinkedRecord(
				connection,
				getRelayHandleKey(CONNECTION, HIERARCHY_ISSUE_SEARCH_CONNECTION),
				issueSearchVariables,
			);

			hasInitialised.current = true;
		});
	}

	return hasInitialised.current;
};
