import { useEffect, useMemo, createContext, useContext, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { Virtualizer } from '@tanstack/virtual-core';

type Virtualization = Virtualizer<HTMLElement, Element>;

/**
 * Subset of virtualizer props, for easy simulate for SSR
 */
export type BaseServerVirtualizer = Pick<Virtualization, 'getTotalSize' | 'getVirtualItems'>;

export type ServerVirtualizer = Pick<
	Virtualization,
	'getTotalSize' | 'measureElement' | 'getVirtualItems'
> & {
	options: Pick<Virtualization['options'], 'scrollMargin'>;
	isScrolling: boolean;
};

// TODO: Cleanup with jsc_virtualizer_rss_store
const VirtualizationContext = createContext<ServerVirtualizer | null>(null);

VirtualizationContext.displayName = 'VirtualizationContext';

/**
 * Returns virtualizer object used to virtualize rows in the issue table.
 * TODO: Cleanup with jsc_virtualizer_rss_store
 *
 * @returns can be `null` if infinite scroll is not enabled
 */
function useVirtualization(): ServerVirtualizer | null {
	const virtualizer = useContext(VirtualizationContext);

	return virtualizer;
}

// TODO: Cleanup with jsc_virtualizer_rss_store
const { Provider } = VirtualizationContext;

// TODO: Cleanup with jsc_virtualizer_rss_store
/**
 * @deprecated
 */
export { Provider as VirtualizationProvider, useVirtualization };

type UseVirtualizerArgs = Parameters<typeof useVirtualizer<HTMLElement, Element>>[0];

/**
 * Create a virtualizer with an specific number of items to be returned when SSR
 * @param options
 * @returns
 */
export const createServerVirtualizer = (
	options: Pick<UseVirtualizerArgs, 'count' | 'estimateSize' | 'getItemKey' | 'scrollMargin'>,
): BaseServerVirtualizer => {
	const { count, estimateSize, getItemKey } = options;
	const totalSize = count * estimateSize(0);
	let start = 0;
	const items = Array.from({ length: count }).map((_, i) => {
		const end = start + estimateSize(i);
		const item = {
			index: i,
			key: getItemKey?.(i) ?? i,
			start,
			end,
			lane: 0,
			size: estimateSize(i),
		};
		start = end;
		return item;
	});

	return {
		getTotalSize() {
			return totalSize;
		},
		getVirtualItems() {
			return items;
		},
	};
};

const dummyVirtualizer: BaseServerVirtualizer = {
	getTotalSize() {
		return 0;
	},
	getVirtualItems() {
		return [];
	},
};

export const useSSRSafeVirtualizer = (options: UseVirtualizerArgs): ServerVirtualizer => {
	const { getScrollElement, count, estimateSize, getItemKey } = options;
	const [shouldUseSSRVirtualizer, setShouldUseSSRVirtualizer] = useState(true);

	const serverVirtualizer = useMemo(() => {
		if (shouldUseSSRVirtualizer) {
			// only calculated when is going to be used
			return createServerVirtualizer({ count, estimateSize, getItemKey });
		}
		return dummyVirtualizer;
	}, [count, estimateSize, getItemKey, shouldUseSSRVirtualizer]);

	const originalVirtualizer: Virtualization = useVirtualizer<HTMLElement, Element>(options);

	/**
	 * We swap to real virtualization when we detect that the user has initiated a scroll
	 */
	useEffect(() => {
		if (!shouldUseSSRVirtualizer) {
			return;
		}

		const element = getScrollElement();

		const onScroll = () => {
			if (!__SERVER__) {
				setShouldUseSSRVirtualizer(false);
				element?.removeEventListener('scroll', onScroll);
			}
		};
		element?.addEventListener('scroll', onScroll);
		return () => {
			element?.removeEventListener('scroll', onScroll);
		};
	}, [shouldUseSSRVirtualizer, getScrollElement]);

	/**
	 * We overwrite the methods to render the items and calculate size
	 * to assure that SSR renders the same as the frontend on the initial
	 * render
	 */
	return useMemo<ServerVirtualizer>(() => {
		if (shouldUseSSRVirtualizer) {
			return {
				...originalVirtualizer,
				...serverVirtualizer,
			};
		}

		// Ensure we return a stable reference between renders when we stop using the SSR virtualizer
		return originalVirtualizer;
	}, [originalVirtualizer, serverVirtualizer, shouldUseSSRVirtualizer]);
};
