/**
 * @generated SignedSource<<040650b167aaedde58fd822786246fbc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_nativeIssueTable_NativeIssueTable_groups$data = {
  readonly __id: string;
  readonly groupByField: string | null | undefined;
  readonly jql: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueTable_nativeIssueTable_groupResults" | "realtimeUpdaterOld_nativeIssueTable_groups" | "realtimeUpdater_nativeIssueTable_groups">;
  readonly " $fragmentType": "ui_nativeIssueTable_NativeIssueTable_groups";
};
export type ui_nativeIssueTable_NativeIssueTable_groups$key = {
  readonly " $data"?: ui_nativeIssueTable_NativeIssueTable_groups$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_nativeIssueTable_NativeIssueTable_groups">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isDensityFull"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isInlineEditingEnabled"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isPaginating"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKey"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectKeys"
    }
  ],
  "kind": "Fragment",
  "name": "ui_nativeIssueTable_NativeIssueTable_groups",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "groupByField"
    },
    {
      "kind": "ScalarField",
      "name": "jql"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "isDensityFull",
          "variableName": "isDensityFull"
        },
        {
          "kind": "Variable",
          "name": "isInlineEditingEnabled",
          "variableName": "isInlineEditingEnabled"
        },
        {
          "kind": "Variable",
          "name": "isPaginating",
          "variableName": "isPaginating"
        },
        {
          "kind": "Variable",
          "name": "projectKey",
          "variableName": "projectKey"
        },
        {
          "kind": "Variable",
          "name": "projectKeys",
          "variableName": "projectKeys"
        }
      ],
      "kind": "FragmentSpread",
      "name": "issueTable_nativeIssueTable_groupResults"
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdater_nativeIssueTable_groups"
    },
    {
      "kind": "FragmentSpread",
      "name": "realtimeUpdaterOld_nativeIssueTable_groups"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraSpreadsheetGroupConnection"
};

(node as any).hash = "22c723102ae9d4394a5a5a63f588ee6d";

export default node;
