import React, { useMemo } from 'react';
import pick from 'lodash/pick';
import { graphql, useFragment } from 'react-relay';
import type { groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$key } from '@atlassian/jira-relay/src/__generated__/groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow.graphql';
import type {
	groupRow_nativeIssueTable_groupRow$data,
	groupRow_nativeIssueTable_groupRow$key,
} from '@atlassian/jira-relay/src/__generated__/groupRow_nativeIssueTable_groupRow.graphql';
import { useInlineCreateHandlers } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

import { useIsItemExpanded } from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { getChildQueryVariablesForJql } from '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-child-query-variables/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getChildQueryVariablesForJql as getChildQueryVariablesForJqlOld } from '../../hierarchy/utils.tsx';
import { useRowContext } from '../../rows/row-context/index.tsx';
import { CHILD_QUERY_PAGE_SIZE, ACTION_COLUMNS, MAX_COLUMNS } from '../../../common/constants.tsx';
import type { Column } from '../../../common/types.tsx';
import { useIsHideDoneItemsEnabled } from '../../../controllers/features/selectors.tsx';
import { FirstGroupIssues } from './first-group-issues/index.tsx';
import { useInitialiseFirstGroupIssues } from './first-group-issues/utils.tsx';
import { GroupHeader } from './group-header/index.tsx';

export type BaseProps = {
	groupConnectionId: string;
	isVirtual: boolean;
	isGroupAtTop?: boolean;
	projectKey?: string | null;
};

type FirstGroupRowProps = BaseProps & {
	firstGroupRow: groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$key;
	loading: boolean;
};

const getGroupFieldValue = (
	fieldValue: groupRow_nativeIssueTable_groupRow$data['fieldValue'],
): string | null => {
	switch (fieldValue?.__typename) {
		case 'JiraJqlPriorityFieldValue':
			return fieldValue?.priority?.priorityId ?? null;
		case 'JiraJqlUserFieldValue':
			return fieldValue?.user?.accountId ?? null;
		case 'JiraJqlStatusFieldValue':
			return fieldValue?.status?.id ?? null;
		case 'JiraJqlNumberFieldValue':
			return fieldValue?.number?.toString() ?? null;
		case 'JiraJqlOptionFieldValue':
			return fieldValue?.option?.optionId ?? null;
		case 'JiraJqlSprintFieldValue':
			return fieldValue?.sprint?.sprintId ?? null;
		case 'JiraJqlGoalsFieldValue':
			return fieldValue?.goal?.id ?? null;
		default:
			return null;
	}
};

export const FirstGroupRow = ({
	firstGroupRow,
	groupConnectionId,
	isVirtual,
	loading,
	isGroupAtTop,
	projectKey = null,
}: FirstGroupRowProps) => {
	const cloudId = useCloudId();
	const firstGroupRowData = useFragment<groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow$key>(
		graphql`
			fragment groupRow_nativeIssueTable_FirstGroupRow_firstGroupRow on JiraSpreadsheetGroup
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean", defaultValue: true }
				isReparentingEnabled: { type: "Boolean", defaultValue: false }
				isDensityFull: { type: "Boolean", defaultValue: false }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
			) {
				id
				jql @required(action: THROW)
				fieldValue {
					__typename
					... on JiraJqlPriorityFieldValue {
						priority {
							priorityId
						}
					}
					... on JiraJqlUserFieldValue {
						user {
							accountId
						}
					}
					... on JiraJqlStatusFieldValue {
						status {
							id
						}
					}
					... on JiraJqlNumberFieldValue {
						number
					}
					... on JiraJqlOptionFieldValue {
						option {
							optionId
						}
					}
					... on JiraJqlSprintFieldValue {
						sprint {
							sprintId
						}
					}
					... on JiraJqlGoalsFieldValue {
						goal {
							id
						}
					}
				}
				...groupHeader_nativeIssueTable
				issues(fieldSetsInput: $fieldSetsInput, first: 50)
					@connection(key: "firstGroupSection_nativeIssueTable__issues") {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					edges {
						__typename
					}
					__id
					...hierarchy_nativeIssueTable_ChildRowsPagination_queryData
						@arguments(
							isInlineEditingEnabled: $isInlineEditingEnabled
							isReparentingEnabled: $isReparentingEnabled
							isDensityFull: $isDensityFull
							projectKeys: $projectKeys
							projectKey: $projectKey
							shouldQueryHasChildren: false
						)
				}
			}
		`,
		firstGroupRow,
	);

	const { columns } = useRowContext();
	const isHideDoneEnabled = useIsHideDoneItemsEnabled();
	const variables = useMemo(() => {
		if (fg('jira_list_hierarchy_extraction')) {
			return {
				...getChildQueryVariablesForJql<Column>({
					cloudId,
					columns,
					jql: firstGroupRowData.jql,
					isReparentingEnabled: false,
					actionColumns: ACTION_COLUMNS,
					amountOfColumns: MAX_COLUMNS,
					pageSize: CHILD_QUERY_PAGE_SIZE,
					isHideDoneItemsEnabled: isHideDoneEnabled,
				}),
				shouldQueryFieldSetsById: false,
				namespace: 'ISSUE_NAVIGATOR',
				shouldQueryHasChildren: false,
			};
		}

		return {
			...getChildQueryVariablesForJqlOld({
				cloudId,
				columns,
				jql: firstGroupRowData.jql,
				isReparentingEnabled: false,
				isHideDoneItemsEnabled: isHideDoneEnabled,
			}),
			shouldQueryFieldSetsById: false,
			namespace: 'ISSUE_NAVIGATOR',
			shouldQueryHasChildren: false,
		};
	}, [cloudId, columns, firstGroupRowData.jql, isHideDoneEnabled]);

	const baseProps = ['cloudId', 'issueSearchInput', 'fieldSetsInput'] as const;
	const propsWithViewConfigInput = [...baseProps, 'viewConfigInput'] as const;

	const hasInitialised = useInitialiseFirstGroupIssues({
		groupId: firstGroupRowData.id,
		groupConnectionId,
		firstGroupItemsConnectionId: firstGroupRowData.issues?.__id ?? null,
		issueSearchVariables: pick(
			variables,
			...(isHideDoneEnabled ? propsWithViewConfigInput : baseProps),
		),
		loading,
	});

	const [isExpanded] = useIsItemExpanded({
		itemId: firstGroupRowData.id,
		groupId: firstGroupRowData.id,
		itemsConnectionId: groupConnectionId,
	});

	let triggerProps;
	if (expVal('jira_spreadsheet_issue_create_in_group', 'isEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const inlineCreateHandlers = useInlineCreateHandlers({
			projectKey,
		});
		triggerProps = inlineCreateHandlers.triggerProps;
	}

	return (
		<>
			{isVirtual && (
				<GroupHeader
					groupHeader={firstGroupRowData}
					groupConnectionId={groupConnectionId}
					isGroupAtTop={isGroupAtTop}
					createChildTriggerProps={triggerProps}
				/>
			)}
			{hasInitialised && isExpanded && (
				<FirstGroupIssues
					variables={variables}
					groupId={firstGroupRowData.id}
					groupConnectionId={groupConnectionId}
					groupFieldValue={getGroupFieldValue(firstGroupRowData.fieldValue)}
				/>
			)}
		</>
	);
};

type GroupRowProps = BaseProps & { groupRow: groupRow_nativeIssueTable_groupRow$key };

export const GroupRow = ({
	groupRow,
	groupConnectionId,
	isVirtual,
	projectKey = null,
}: GroupRowProps) => {
	const groupRowData = useFragment<groupRow_nativeIssueTable_groupRow$key>(
		graphql`
			fragment groupRow_nativeIssueTable_groupRow on JiraSpreadsheetGroup {
				id
				jql @required(action: THROW)
				fieldValue {
					__typename
					... on JiraJqlPriorityFieldValue {
						priority {
							priorityId
						}
					}
					... on JiraJqlUserFieldValue {
						user {
							accountId
						}
					}
					... on JiraJqlStatusFieldValue {
						status {
							id
						}
					}
					... on JiraJqlNumberFieldValue {
						number
					}
					... on JiraJqlOptionFieldValue {
						option {
							optionId
						}
					}
					... on JiraJqlSprintFieldValue {
						sprint {
							sprintId
						}
					}
					... on JiraJqlGoalsFieldValue {
						goal {
							id
						}
					}
				}
				...groupHeader_nativeIssueTable
			}
		`,
		groupRow,
	);

	const { renderChildRows } = useRowContext();
	let triggerProps;
	if (expVal('jira_spreadsheet_issue_create_in_group', 'isEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const inlineCreateHandlers = useInlineCreateHandlers({
			projectKey,
		});
		triggerProps = inlineCreateHandlers.triggerProps;
	}

	return (
		groupRowData && (
			<>
				{isVirtual && (
					<GroupHeader
						groupHeader={groupRowData}
						groupConnectionId={groupConnectionId}
						createChildTriggerProps={triggerProps}
					/>
				)}
				{renderChildRows?.({
					parentItemId: groupRowData.id,
					itemsConnectionId: groupConnectionId,
					depth: 0,
					type: 'GROUP_CHILDREN',
					groupFieldValue: getGroupFieldValue(groupRowData.fieldValue),
					groupJql: groupRowData.jql,
				})}
			</>
		)
	);
};
