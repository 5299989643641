// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createStateHook,
	type Action,
	createActionsHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { ServerVirtualizer } from './index.tsx';

export type VirtualItemsMap = {
	[key: string]: boolean;
};

type State = {
	virtualizer: ServerVirtualizer | null;
	virtualItemsMap: VirtualItemsMap;
};

const initialState = {
	virtualizer: null,
	virtualItemsMap: {},
};

const actions = {
	setVirtualizer:
		(virtualizer: ServerVirtualizer | null): Action<State> =>
		({ setState }) => {
			setState({ virtualizer });
		},
	setVirtualItemsMap:
		(virtualItemsMap: VirtualItemsMap): Action<State> =>
		({ setState }) => {
			setState({ virtualItemsMap });
		},
};

export const VirtualizationContainer = createContainer();

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'VirtualizerStore',
	containedBy: VirtualizationContainer,
});

export const useVirtualizationActions = createActionsHook(Store);

// Exported for testing only
export const useVirtualizerStore = createHook(Store);

const getVirtualizerSelector = (state: State) => state.virtualizer;

export const useVirtualization = createStateHook(Store, {
	selector: getVirtualizerSelector,
});

const getVirtualItemsMapSelector = (state: State) => state.virtualItemsMap;

export const useVirtualItemsMap = createStateHook(Store, {
	selector: getVirtualItemsMapSelector,
});
