import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { commitLocalUpdate } from 'relay-runtime';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal/src/model/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueParent } from '@atlassian/jira-issue-parent-services/src/services/types.tsx';
import { JiraIssueAri } from '@atlassian/ari/jira';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags/src/services/index.tsx';
import { useGetReparentUpdater } from '../../services/reparent-mutation/utils.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { UPDATE_ITEM_PARENT_FAIL_GENERIC_FLAG } from '../../services/reparent-mutation/constants.tsx';

export const useReparentingFromModal = ({ projectKey }: { projectKey?: string }) => {
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();

	const environment = useRelayEnvironment();
	const updateParentConnections = useGetReparentUpdater();

	return useCallback(
		(
			updatedIssues: Issue[],
			newParentId: IssueId | null,
			newIssueParent: IssueParent | null,
			requestPromise: Promise<unknown>,
		) => {
			if (updatedIssues.length) {
				const upatedIssue: Issue = updatedIssues[0];
				if (upatedIssue.id) {
					const ariIssueId = JiraIssueAri.create({
						issueId: upatedIssue.id,
						siteId: cloudId,
					}).toString();

					commitLocalUpdate(environment, (store) => {
						updateParentConnections({
							store,
							issueId: ariIssueId,
							oldParentId: upatedIssue.parentId,
							newParentId,
							index: 0,
							projectKey,
						});
					});
					requestPromise.catch((error: Error) => {
						// Rollback
						commitLocalUpdate(environment, (store) => {
							updateParentConnections({
								store,
								issueId: ariIssueId,
								oldParentId: newParentId,
								newParentId: upatedIssue.parentId,
								index: 0,
								projectKey,
							});
						});

						fireErrorAnalytics({
							meta: {
								id: 'issueTableReparentMutationFromModal',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
						showFlag(UPDATE_ITEM_PARENT_FAIL_GENERIC_FLAG);
					});
				}
			}
		},
		[cloudId, environment, projectKey, showFlag, updateParentConnections],
	);
};
