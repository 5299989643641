import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	realtimeUpdateExceededMaxHeaderNonFinal: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-header-non-final',
		defaultMessage: 'Your data might be stale',
		description:
			'Header that shows when the data could be stale because we could not process the real time updates',
	},
	realtimeUpdateExceededMaxDescriptionNonFinal: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-description-non-final',
		defaultMessage: 'Please refresh the page to see the latest updates',
		description:
			'Description that shows when the data could be stale because we could not process the real time updates',
	},
	realtimeUpdateExceededMaxRefreshButtonNonFinal: {
		id: 'native-issue-table.ui.realtime-updater.realtime-update-exceeded-max-refresh-button-non-final',
		defaultMessage: 'Refresh',
		description: 'Call to action to refresh the page  when the data could be stale',
	},
});
