import React, { useCallback, memo, type RefCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useInlineCreateHandlers } from '@atlassian/jira-issue-table-inline-issue-create/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import type {
	rows_nativeIssueTable$key,
	rows_nativeIssueTable$data,
} from '@atlassian/jira-relay/src/__generated__/rows_nativeIssueTable.graphql';
import { getFlatListKey } from '@atlassian/jira-issue-table-hierarchy/src/common/utils/get-flat-list-key/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	useHierarchyActions,
	getShowMoreItemKey,
	getChildIssueCreateItemKey,
} from '@atlassian/jira-issue-table-hierarchy/src/controllers/hierarchy/index.tsx';
import { LoadMoreItemsRow } from '../../common/ui/load-more-items-row/index.tsx';
import {
	useIsIssueHierarchyEnabled,
	useIsVirtualizationEnabled,
	useProjectContext,
} from '../../controllers/features/selectors.tsx';
import Row from '../row/index.tsx';
import { useRowContext } from './row-context/index.tsx';
import { VirtualizedRows } from './virtualized-rows-hierarchy/index.tsx';
import { VirtualizedRows as VirtualizedRowsFlatList } from './virtualized-rows/index.tsx';

export type RowsProps = {
	issueResults: rows_nativeIssueTable$key;
	itemsConnectionId: string;
	parentItemId: string;
	depth?: number;
	parentIssueAri?: string;
	parentItemConnectionId?: string;
	parentIssueRelayConnectionId?: string;
	hasNext?: boolean;
	isLoadingNext?: boolean;
	isChildIssueCreateRowVisible?: boolean;
	projectKey?: string | undefined;
	onLoadNextItems?: () => void;
	renderChildIssueCreateRow?: () => React.ReactNode;
};

const HierarchyRow = memo(
	({
		depth,
		itemsConnectionId,
		parentItemConnectionId,
		parentIssueAri,
		rowsData,
		index,
		isVirtual,
		isIssueHierarchyEnabled,
		measureElement,
		projectKey,
	}: {
		depth: number;
		itemsConnectionId: string;
		parentItemConnectionId: string;
		parentIssueAri: string;
		rowsData: rows_nativeIssueTable$data;
		index: number;
		isVirtual?: boolean;
		isIssueHierarchyEnabled?: boolean;
		measureElement?: RefCallback<HTMLTableRowElement>;
		projectKey: string | undefined;
	}) => {
		const issueRow = rowsData?.edges?.[index];
		const hierarchyLevel = issueRow?.node?.issueTypeField?.issueType?.hierarchy?.level;
		const { renderChildRows } = useRowContext();

		const { isFormVisible, formProps, triggerProps } = useInlineCreateHandlers({
			projectKey: useProjectContext(),
			...(hierarchyLevel != null && {
				minHierarchyLevel: hierarchyLevel - 1,
				maxHierarchyLevel: hierarchyLevel - 1,
			}),
		});

		return (
			<ContextualAnalyticsData attributes={{ rowIndex: index, tableRowNumber: index }}>
				{isVirtual && (
					<Row
						rowIndex={index}
						itemsConnectionId={rowsData.__id}
						issue={issueRow?.node ?? null}
						issueEdge={issueRow ?? null}
						fieldSets={issueRow?.fieldSets ?? null}
						depth={depth}
						parentIssueAri={parentIssueAri}
						parentItemConnectionId={parentItemConnectionId}
						parentIssueRelayConnectionId={itemsConnectionId}
						triggerProps={triggerProps}
						isFormVisible={isFormVisible}
						measureElement={measureElement}
						{...(fg('jsc_list_reparenting')
							? { hierarchyLevel, issueKey: issueRow?.node?.key, projectKey }
							: {})}
					/>
				)}
				{issueRow?.node?.issueId &&
					isIssueHierarchyEnabled &&
					fg('jsc_m2_hierarchy_fe_changes') &&
					renderChildRows?.({
						issueKey: issueRow?.node?.key,
						parentItemId: issueRow?.node?.issueId,
						itemsConnectionId: rowsData.__id,
						depth,
						parentIssueAri: issueRow?.node?.id,
						parentItemConnectionId: issueRow?.__id,
						parentIssueRelayConnectionId: itemsConnectionId,
						type: 'PARENT_CHILDREN',
						isFormVisible,
						formProps,
						hasChildren: issueRow?.hasChildren ?? false,
					})}
			</ContextualAnalyticsData>
		);
	},
);

export const Rows = ({
	projectKey,
	issueResults,
	depth = 0,
	parentItemId,
	itemsConnectionId,
	parentIssueAri,
	parentItemConnectionId,
	parentIssueRelayConnectionId,
	isLoadingNext,
	hasNext,
	isChildIssueCreateRowVisible,
	onLoadNextItems,
	renderChildIssueCreateRow,
}: RowsProps) => {
	const rowsData = useFragment<rows_nativeIssueTable$key>(
		graphql`
			fragment rows_nativeIssueTable on JiraIssueConnection
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isReparentingEnabled: { type: "Boolean!" }
				isDensityFull: { type: "Boolean!" }
				isJscInfiniteScrollEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider"
				}
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
			) {
				__id
				edges {
					__id
					...row_nativeIssueTable_issueEdge
						@arguments(
							isInlineEditingEnabled: $isInlineEditingEnabled
							isReparentingEnabled: $isReparentingEnabled
							isDensityFull: $isDensityFull
							projectKeys: $projectKeys
							projectKey: $projectKey
							shouldQueryHasChildren: $shouldQueryHasChildren
						)
					hasChildren(filterByProjectKeys: $projectKeys)
						@include(if: $isReparentingEnabled)
						@optIn(to: "JiraSpreadsheetComponent-M2")
					node {
						id
						key
						issueId
						issueTypeField {
							issueType {
								hierarchy {
									level
								}
							}
						}
						...row_nativeIssueTable_issue
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isDensityFull: $isDensityFull
							)
					}
					fieldSets(first: 500)
						@optIn(to: "JiraSpreadsheetComponent-M1")
						@include(if: $isJscInfiniteScrollEnabled) {
						...row_nativeIssueTable_fieldSets
							@arguments(
								isInlineEditingEnabled: $isInlineEditingEnabled
								isDensityFull: $isDensityFull
							)
					}
				}
			}
		`,
		issueResults,
	);
	let isIssueHierarchyEnabled = false;
	let populateConnection = noop;
	if (fg('jsc_m2_hierarchy_fe_changes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		isIssueHierarchyEnabled = useIsIssueHierarchyEnabled();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ populateConnection } = useHierarchyActions());
	}

	const { columns, groupId } = useRowContext();

	const populateConnectionArgs = useMemo(
		() => ({
			parentItemId,
			itemsConnectionId: rowsData.__id,
			parentConnectionId: itemsConnectionId,
			list:
				rowsData?.edges
					?.map(
						(edge) =>
							edge?.node && {
								key: edge.node.key,
								itemId: edge.node.issueId,
								groupId,
							},
					)
					.filter(Boolean) ?? [],
			hasNext,
			groupId,
			isChildIssueCreateRowVisible,
		}),
		[
			parentItemId,
			itemsConnectionId,
			rowsData.__id,
			rowsData?.edges,
			hasNext,
			groupId,
			isChildIssueCreateRowVisible,
		],
	);
	const prevPopulateConnectArgs = usePrevious(populateConnectionArgs);

	if (
		!isEqual(populateConnectionArgs, prevPopulateConnectArgs) &&
		fg('jsc_m2_hierarchy_fe_changes')
	) {
		populateConnection(populateConnectionArgs);
	}

	const renderRowItem = useCallback(
		(index: number, measureElement?: RefCallback<HTMLTableRowElement>) => {
			const issueRow = rowsData?.edges?.[index];

			return (
				<ContextualAnalyticsData
					key={issueRow?.node?.key || index}
					attributes={{ rowIndex: index, tableRowNumber: index }}
				>
					<Row
						rowIndex={index}
						itemsConnectionId={rowsData.__id}
						issue={issueRow?.node ?? null}
						issueEdge={issueRow ?? null}
						fieldSets={issueRow?.fieldSets ?? null}
						depth={depth}
						parentIssueAri={parentIssueAri}
						parentItemConnectionId={parentItemConnectionId}
						parentIssueRelayConnectionId={parentIssueRelayConnectionId}
						measureElement={measureElement}
						{...(fg('jsc_list_reparenting') ? { projectKey, issueKey: issueRow?.node?.key } : {})}
					/>
				</ContextualAnalyticsData>
			);
		},
		[
			rowsData?.edges,
			rowsData.__id,
			depth,
			parentIssueAri,
			parentItemConnectionId,
			parentIssueRelayConnectionId,
			projectKey,
		],
	);

	const renderRows = useCallback(
		(
			isItemVirtual?: (itemId: string) => boolean,
			measureElement?: RefCallback<HTMLTableRowElement>,
		) => {
			if (fg('jsc_m2_hierarchy_fe_changes')) {
				const childIssueCreateItemId = getFlatListKey({
					itemId: getChildIssueCreateItemKey(parentItemId),
					groupId,
				});
				const isChildIssueCreateRowVirtual = isItemVirtual?.(childIssueCreateItemId) ?? true;
				const showMoreItemId = getFlatListKey({
					itemId: getShowMoreItemKey(parentItemId),
					groupId,
				});
				const isShowMoreItemVirtual = isItemVirtual?.(showMoreItemId) ?? true;

				return (
					<>
						{isIssueHierarchyEnabled &&
							isChildIssueCreateRowVirtual &&
							renderChildIssueCreateRow?.()}
						{rowsData?.edges?.map((edge, index: number) => {
							const flatListIssueId = getFlatListKey({
								itemId: edge?.node?.issueId ?? '',
								groupId,
							});
							const isVirtual = isItemVirtual?.(flatListIssueId) ?? true;

							return isIssueHierarchyEnabled ? (
								<HierarchyRow
									key={flatListIssueId}
									itemsConnectionId={itemsConnectionId}
									isVirtual={isVirtual}
									index={index}
									rowsData={rowsData}
									depth={depth}
									parentItemConnectionId={parentItemConnectionId ?? ''}
									parentIssueAri={parentIssueAri ?? ''}
									isIssueHierarchyEnabled={isIssueHierarchyEnabled}
									measureElement={measureElement}
									projectKey={projectKey}
								/>
							) : (
								isVirtual && renderRowItem(index, measureElement)
							);
						})}
						{hasNext && isShowMoreItemVirtual && (
							<LoadMoreItemsRow
								isLoadingNext={isLoadingNext}
								columnsCount={columns.length}
								loadNext={onLoadNextItems ?? noop}
							/>
						)}
					</>
				);
			}

			return rowsData?.edges?.map(
				(_, index: number) => renderRowItem(index, measureElement) ?? null,
			);
		},
		[
			columns.length,
			depth,
			groupId,
			hasNext,
			isIssueHierarchyEnabled,
			isLoadingNext,
			itemsConnectionId,
			onLoadNextItems,
			parentIssueAri,
			parentItemConnectionId,
			parentItemId,
			projectKey,
			renderChildIssueCreateRow,
			renderRowItem,
			rowsData,
		],
	);

	const isVirtualizationEnabled = useIsVirtualizationEnabled();

	if (isVirtualizationEnabled) {
		if (fg('jsc_m2_hierarchy_fe_changes')) {
			const isGrouping = Boolean(groupId);
			return (
				<VirtualizedRows hasSpacers={depth === 0 && !isGrouping} columnsCount={columns.length}>
					{renderRows}
				</VirtualizedRows>
			);
		}

		return (
			<VirtualizedRowsFlatList renderRow={renderRowItem}>{renderRows}</VirtualizedRowsFlatList>
		);
	}

	return renderRows();
};
