/** @jsx jsx */
import React, { useState, useCallback, useMemo, useEffect, type KeyboardEvent } from 'react';
import { styled, jsx } from '@compiled/react';
import { graphql, useLazyLoadQuery, usePaginationFragment, useFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';
import type { OperationType } from 'relay-runtime';
import { xcss, Inline, Box } from '@atlaskit/primitives';
import Select, { type FormatOptionLabelMeta } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import type { AkSelectStyles } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SEARCH_DEBOUNCE_TIMEOUT } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-field-select-base/src/ui/filter-options-by-label-and-filter-values/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef$key as IssueTypeFragment } from '@atlassian/jira-relay/src/__generated__/issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef.graphql';
import type { issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef$key as IssueTypeConnectionFragment } from '@atlassian/jira-relay/src/__generated__/issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef.graphql';
import issueTypeSearchRefetchQuery, {
	type issueType_SearchRefetchQuery as IssueTypeRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/issueType_SearchRefetchQuery.graphql';
import messages from './messages.tsx';
import type {
	Option,
	IssueTypeEditViewWithFieldOptionsFragmentProps,
	IssueTypeEditViewProps,
	IssueTypeEditViewWithIssueTypeConnectionProps,
} from './types.tsx';

const iconOnlySelectStyles: AkSelectStyles = {
	menuPortal: (base) => ({ ...base, zIndex: layers.modal }),
	menu: (base) => ({
		...base,
		zIndex: layers.modal,
		width: '120px',
	}),
};

/**
 * IssueTypeEditViewWithFieldOptionsFragment is a version of the edit view that allows
 * the passing of a fragment for experiences like issue transition screen that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [IssueTypeEditViewWithFieldOptionsFragmentProps](./types.tsx)
 */
export const IssueTypeEditViewWithFieldOptionsFragment = ({
	queryFragmentRef,
	fieldId,
	filterOptionsById = null,
	...props
}: IssueTypeEditViewWithFieldOptionsFragmentProps) => {
	const { data: issueTypesSearchData, refetch: refetchSuggestions } = usePaginationFragment<
		IssueTypeRefetchQuery,
		IssueTypeFragment
	>(
		graphql`
			fragment issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef on Query
			@refetchable(queryName: "issueType_SearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 1000 }
				after: { type: "String", defaultValue: null }
				filterById: { type: "JiraFieldOptionIdsFilterInput" }
			) {
				node(id: $id) {
					... on JiraIssueTypeField {
						issueTypes(searchBy: $searchBy, first: $first, after: $after, filterById: $filterById)
							@connection(key: "issueType_issueFieldIssueTypeEditviewFull_issueTypes") {
							edges {
								__typename
							}
							...issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef
						}
					}
				}
			}
		`,
		queryFragmentRef,
	);

	const onGetRefetchVariables = useCallback(
		(searchBy: string) => ({ id: fieldId, searchBy, filterById: filterOptionsById }),
		[fieldId, filterOptionsById],
	);

	return (
		<IssueTypeEditViewWithIssueTypeConnection
			{...props}
			issueTypesFragmentRef={issueTypesSearchData.node?.issueTypes ?? null}
			onGetRefetchVariables={onGetRefetchVariables}
			refetchQuery={issueTypeSearchRefetchQuery}
			onRefetch={refetchSuggestions}
		/>
	);
};

/**
 * IssueTypeEditViewWithIssueTypeConnection is a version of the edit view that allows
 * the passing of a issue type connection fragment for experiences like GIC that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [IssueTypeEditViewWithIssueTypeConnectionProps](./types.tsx)
 */
export const IssueTypeEditViewWithIssueTypeConnection = <
	TQuery extends OperationType,
	TKey extends KeyType | null,
>({
	autoFocus = false,
	issueTypesFragmentRef,
	onChange,
	onGetRefetchVariables,
	value,
	noOptionsMessage,
	loadingMessage,
	isInvalid,
	isDisabled = false,
	inputId,
	menuPosition,
	menuPlacement,
	refetchQuery,
	onRefetch,
	openMenuOnFocus,
	spacing,
	stopPropagationOfMenuEscape,
	iconsOnly = false,
	appearance = 'default',
	isSearchable = true,
}: IssueTypeEditViewWithIssueTypeConnectionProps<TQuery, TKey>) => {
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(autoFocus);

	const { formatMessage } = useIntl();
	const getNoOptionsMessage = useCallback(
		(): string => noOptionsMessage ?? formatMessage(messages.empty),
		[formatMessage, noOptionsMessage],
	);

	const getLoadingMessage = useCallback(
		(): string => loadingMessage ?? formatMessage(messages.loading),
		[formatMessage, loadingMessage],
	);
	const placeholderMessage = formatMessage(messages.placeholder);

	// #region Relay
	// suggestions fragment with pagination and refetch
	const data = useFragment<IssueTypeConnectionFragment>(
		graphql`
			fragment issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithIssueTypeConnection_issueTypesFragmentRef on JiraIssueTypeConnection {
				edges {
					node {
						value: id
						label: name
						avatar {
							small
						}
					}
				}
			}
		`,
		issueTypesFragmentRef,
	);
	// #endregion

	// #region Common state (might be handy for other components)
	const [searchByString, setSearchByString] = useState<string>('');
	// #endregion

	// #region Debounced suspensless refetch helpers
	const [searchSuspenselessRefetch, isLoading, lastFetchError] = useSuspenselessRefetch(
		refetchQuery,
		onRefetch,
	);

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetch,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	useEffect(() => {
		if (autoFocus) {
			searchSuspenselessRefetch(onGetRefetchVariables(searchByString));
		}
	}, [searchSuspenselessRefetch, autoFocus, searchByString, onGetRefetchVariables]);

	const onKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>) => {
			if (stopPropagationOfMenuEscape && menuIsOpen && e.key === 'Escape') {
				e.stopPropagation();
			}
		},
		[menuIsOpen, stopPropagationOfMenuEscape],
	);

	// #region Common callbacks for the selector
	const openMenu = () => setMenuIsOpen(true);

	const closeMenu = () => setMenuIsOpen(false);

	const onSelectFocus = useCallback((): void => {
		searchSuspenselessRefetch(onGetRefetchVariables(searchByString));
	}, [searchSuspenselessRefetch, searchByString, onGetRefetchVariables]);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString: string): void => {
			setSearchByString(newSearchByString);
			searchDebouncedSuspenselessRefetch(onGetRefetchVariables(newSearchByString || ''));
		},
		[onGetRefetchVariables, searchDebouncedSuspenselessRefetch],
	);
	// #endregion

	// #region Transform options data from relay to AK Select
	const defaultFailedOption = useMemo(
		() => ({
			label: formatMessage(messages.failedFetch),
			value: '',
			avatar: {
				small: '',
			},
			isDisabled: true,
		}),
		[formatMessage],
	);

	const renderIconOnlyOption = (
		option: Option,
		formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
	) => (
		<OptionWrapper>
			<Inline xcss={inlineIconStyle}>
				<img
					src={option.avatar?.small || ''}
					alt={option.label}
					width={2 * gridSize}
					height={2 * gridSize}
				/>
			</Inline>
			{formatOptionLabelMeta.context !== 'value' && <Box>{option.label}</Box>}
		</OptionWrapper>
	);

	const renderIconWithTextOption = (option: Option) => (
		<OptionWrapper>
			<ImgSpan>
				<img
					src={option.avatar?.small || ''}
					alt={option.label}
					width={2 * gridSize}
					height={2 * gridSize}
				/>
			</ImgSpan>
			<>{option.label}</>
		</OptionWrapper>
	);

	const selectFieldOptions: Option[] = useMemo(() => {
		// checks for query errors
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}
		return (
			data?.edges
				?.map((edge) => edge?.node)
				.filter(Boolean)
				.map(({ label: optionLabel, value: optionValue, avatar }) => ({
					label: optionLabel ?? '',
					value: optionValue,
					avatar,
				})) ?? []
		);
	}, [lastFetchError, data, defaultFailedOption]);

	// #endregion

	return (
		<Select
			aria-label={formatMessage(messages.label)}
			formatOptionLabel={iconsOnly ? renderIconOnlyOption : renderIconWithTextOption}
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
			styles={iconsOnly ? iconOnlySelectStyles : defaultSelectStyles}
			value={value}
			options={selectFieldOptions}
			menuIsOpen={menuIsOpen}
			placeholder={placeholderMessage}
			noOptionsMessage={getNoOptionsMessage}
			loadingMessage={getLoadingMessage}
			filterOption={filterOptionByLabelAndFilterValues}
			onInputChange={onSearchByStringChangeFunction}
			onChange={onChange}
			openMenuOnFocus={openMenuOnFocus}
			onFocus={onSelectFocus}
			onKeyDown={onKeyDown}
			autoFocus={autoFocus}
			isInvalid={isInvalid}
			isDisabled={isDisabled}
			isLoading={isLoading && menuIsOpen}
			inputId={inputId}
			menuPosition={menuPosition}
			menuPlacement={menuPlacement}
			onMenuOpen={openMenu}
			onMenuClose={closeMenu}
			spacing={spacing}
			appearance={appearance}
			isSearchable={isSearchable}
		/>
	);
};

/**
 * Simple use case variant which handles its own fetching of suggestions data.
 * Use the IssueTypeEditViewWithFieldOptionsFragment variant if you want to handle
 * prefilling the suggestions data as part of a larger query instead of on mount / on focus.
 *
 * @param props [IssueTypeEditViewProps](./types.tsx)
 */
export const IssueTypeEditView = (props: IssueTypeEditViewProps) => {
	const suggestionsData = useLazyLoadQuery<IssueTypeRefetchQuery>(
		graphql`
			query issueType_issueTypeSearchQuery($id: ID!, $filterById: JiraFieldOptionIdsFilterInput) {
				...issueType_issueFieldIssueTypeEditviewFull_IssueTypeEditViewWithFieldOptionsFragment_queryFragmentRef
					@arguments(id: $id, searchBy: "", filterById: $filterById)
			}
		`,
		{ id: props.fieldId, filterById: props.filterOptionsById },
		{ fetchPolicy: 'store-only' },
	);

	return (
		<IssueTypeEditViewWithFieldOptionsFragment {...props} queryFragmentRef={suggestionsData} />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImgSpan = styled.span({
	display: 'flex',
	paddingRight: token('space.100'),
});

const inlineIconStyle = xcss({
	marginRight: 'space.100',
});
