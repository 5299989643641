import React, { memo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { NumberReadView } from '@atlassian/jira-issue-field-number-readview-full/src/ui/number/index.tsx';
import { NumberInlineEditViewWithIsEditable } from '@atlassian/jira-issue-field-number-inline-edit-full/src/ui/number/index.tsx';
import type { number_nativeIssueTable_NumberCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/number_nativeIssueTable_NumberCell_fieldRef.graphql';
import type { number_nativeIssueTable_NumberInlineEditCell_fieldRef$key } from '@atlassian/jira-relay/src/__generated__/number_nativeIssueTable_NumberInlineEditCell_fieldRef.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { KNOWN_COLUMN_TYPES } from '@atlassian/jira-issue-table-common/src/constants.tsx';
import { StoryPointEstimateCell } from '../story-point-estimate/index.tsx';
import { INLINE_EDIT_ANALYTICS_ATTRIBUTES } from '../common/inline-edit-analytics-attributes.tsx';
import { useLazyLoadEditability } from '../../../../services/lazy-editability-loader/index.tsx';
import { useIsInlineEditingExtendedFieldSupportEnabled } from '../../../../controllers/features/selectors.tsx';

export type Props = {
	fieldRef: number_nativeIssueTable_NumberCell_fieldRef$key;
};

export const NumberCell = memo(({ fieldRef }: Props) => {
	const data = useFragment<number_nativeIssueTable_NumberCell_fieldRef$key>(
		graphql`
			fragment number_nativeIssueTable_NumberCell_fieldRef on JiraNumberField
			@argumentDefinitions(
				isInlineEditingEnabled: { type: "Boolean!" }
				isJscInlineEditRefactorEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider"
				}
			) {
				type
				isStoryPointField
				...number_issueFieldNumberReadviewFull_NumberReadView
					@skip(if: $isJscInlineEditRefactorEnabled)
				...number_nativeIssueTable_NumberInlineEditCell_fieldRef
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
					@include(if: $isJscInlineEditRefactorEnabled)
				...storyPointEstimate_nativeIssueTable_StoryPointEstimateCell
					@arguments(isInlineEditingEnabled: $isInlineEditingEnabled)
			}
		`,
		fieldRef,
	);

	if (data.isStoryPointField || data.type === KNOWN_COLUMN_TYPES.STORY_POINT_ESTIMATE) {
		return <StoryPointEstimateCell fieldRef={data} />;
	}

	return fg('jsc_inline_editing_field_refactor') ? (
		<NumberInlineEditCell fieldRef={data} />
	) : (
		<Box paddingBlock="space.050" paddingInline="space.100">
			<NumberReadView fragmentRef={data} />
		</Box>
	);
});

type NumberInlineEditCellProps = {
	fieldRef: number_nativeIssueTable_NumberInlineEditCell_fieldRef$key;
};

const NumberInlineEditCell = memo(({ fieldRef }: NumberInlineEditCellProps) => {
	const data = useFragment<number_nativeIssueTable_NumberInlineEditCell_fieldRef$key>(
		graphql`
			fragment number_nativeIssueTable_NumberInlineEditCell_fieldRef on JiraNumberField
			@argumentDefinitions(isInlineEditingEnabled: { type: "Boolean!" }) {
				id
				lazyIsEditableInIssueView @include(if: $isInlineEditingEnabled)
				...number_issueFieldNumberInlineEditFull_NumberInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fieldRef,
	);

	const isInlineEditingExtendedFieldSupportEnabled =
		useIsInlineEditingExtendedFieldSupportEnabled();
	// If the property does not exist in fragment data then we know it has been conditionally excluded because inline
	// editing is disabled. In this case we explicitly set isEditable to false to bypass the lazy editability query.
	const isEditable =
		'lazyIsEditableInIssueView' in data && isInlineEditingExtendedFieldSupportEnabled
			? data.lazyIsEditableInIssueView
			: false;
	useLazyLoadEditability(data.id, isEditable);

	return (
		<NumberInlineEditViewWithIsEditable
			attributes={INLINE_EDIT_ANALYTICS_ATTRIBUTES}
			spacing="default"
			editViewPopup
			editViewPopupAlignBlock="center"
			fragmentRef={data}
			isEditable={isEditable ?? false}
			readViewFitContainerHeight
		/>
	);
});
