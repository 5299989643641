import { useEffect } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStateHook,
	createActionsHook,
	createStore,
	type Action,
} from '@atlassian/react-sweet-state';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';

type ConnectionDetailsBase = {
	connectionId: string;
	first: number;
};

export type ParentConnectionMetadata = {
	type: 'PARENT_CHILDREN';
	parentRelayId: string;
	parentId: string;
};

export type GroupConnectionMetadata = {
	type: 'GROUP_CHILDREN';
	fieldValue: string | null;
	groupId: string;
	jql: string;
};

export type RootConnectionMetadata = {
	type: 'ROOT_ISSUES';
};

export type ConnectionMetadata =
	| ParentConnectionMetadata
	| GroupConnectionMetadata
	| RootConnectionMetadata;

export type ConnectionDetails = ConnectionDetailsBase & ConnectionMetadata;

export interface State {
	connections: { [connectionId: string]: ConnectionDetails };
}

const INITIAL_CONNECTIONS_LIST_STATE: State = {
	connections: {},
};

export const ConnectionsListContainer = createContainer();

const getKeyForConnection = (connection: ConnectionDetails) => {
	let key = connection.connectionId;
	if (connection.type === 'PARENT_CHILDREN') {
		key = connection.parentId;
	}
	if (connection.type === 'GROUP_CHILDREN') {
		key = connection.groupId;
	}
	// add some formatting to ensure it is not used for another purpose
	return `KEY_${key}`;
};

const actions = {
	addConnection:
		(connection: ConnectionDetails): Action<State> =>
		({ setState, getState }) => {
			const { connections } = getState();
			const key = getKeyForConnection(connection);
			setState({
				connections: { ...connections, [key]: connection },
			});
		},
	removeConnection:
		(connection: ConnectionDetails): Action<State> =>
		({ setState, getState }) => {
			const { connections } = getState();
			const key = getKeyForConnection(connection);
			const { [key]: _, ...newConnections } = connections;
			setState({ connections: newConnections });
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState: INITIAL_CONNECTIONS_LIST_STATE,
	name: 'ConnectionsListStore',
	containedBy: ConnectionsListContainer,
	actions,
	handlers: {
		onDestroy:
			() =>
			({ getState }) => {
				const { connections } = getState();
				const environment = getRelayEnvironment();

				Object.values(connections).forEach((connection) => {
					environment.commitUpdate((store) => {
						const connectionToInvalidate = store.get(connection.connectionId);
						if (connectionToInvalidate) {
							connectionToInvalidate.invalidateRecord();
						}
					});
				});
			},
	},
});

export const useConnectionsList = createStateHook<State, Actions>(Store);
export const useConnectionsListActions = createActionsHook<State, Actions>(Store);

export const useRegisterConnection = (connection?: ConnectionDetails) => {
	const { addConnection } = useConnectionsListActions();

	useEffect(() => {
		if (connection) {
			addConnection(connection);
		}
	}, [addConnection, connection]);
};
